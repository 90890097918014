import React, { useContext, useState } from "react";
import { Figure } from "./figure-tag";
import { BUTTON_BG_MAP, renderLink } from "./serializers-link";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { PromoBlock } from "./sections/promo";
import { BlockTable } from "./table";
import { ExternalVideo } from "./serializers-video";
import {
  InlineCardPlaybook,
  InlineCardQuote,
  InlineCardWorkflow,
} from "./inline-card";
import { Divider } from "./sections/divider";
import { CallToAction } from "./serializers-cta";
import { ArcadeEmbed } from "./arcade-embed";
import { cn, imageBuilder } from "../lib/helpers";
import { RotateText } from "./rotate-text";
import { SiteContext } from "./global/site-context";
import { tocId } from "../templates/documentation";
import { Image } from "./sections/featured-media";
import { VideoPopover } from "./video-popover";
import { HubspotWebform } from "./sections/hubspot-webform";
import { ImageBuilder } from "./global/image-builder";
import { Popover } from "./popover";
import { textToSlug } from "../../helpers";
import { CustomBlocks } from "./serializers-custom";
import { Callout } from "./serializers-callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas as iconSet } from "@fortawesome/free-solid-svg-icons";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import { TinyDownArrow } from "./icon/tiny-down-arrow";
import { SectionContext } from "./sections/context";

import * as style from "./global/global.module.css";

SyntaxHighlighter.registerLanguage("javascript", js);

export const serializers = {
  types: {
    /* eslint-disable-next-line react/display-name */
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: Figure,
    externalVideo: ExternalVideo,
    promo: PromoBlock,
  },
  marks: {
    link: renderLink,
    callout: Callout,
    icon: ({ mark, children }) => (
      <span className="relative inline-flex items-baseline">
        {mark.image?.asset?.extension === "svg" ? (
          <Image
            src={imageBuilder(mark.image).quality(100).auto("format").url()}
            className="self-center mr-1.5 ml-1"
          />
        ) : (
          <ImageBuilder image={mark.image} height={12} className="mr-1" />
        )}
        <span>{children}</span>
      </span>
    ),
    smallText: ({ children }) => (
      <span className="text-sm leading-normal">{children}</span>
    ),
    centered: ({ children }) => (
      <span className="flex justify-center wat">{children}</span>
    ),
    gradient: ({ children }) => (
      <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-orange-900">
        {children}
      </span>
    ),
    dimmed: ({ children }) => (
      <span className="text-auxiliary text-base">{children}</span>
    ),
    brandColor: ({ children }) => (
      <span className="text-link tracking-tight">{children}</span>
    ),
    customColor: ({ mark, children }) => (
      <span style={{ color: mark.color }}>{children}</span>
    ),
    divider: () => <Divider />,
    rotate: ({ mark, children }) => {
      return <RotateText words={mark.words} />;
    },
    // inline, not block
    code: ({ children }) => (
      <span
        className="text-red text-sm font-mono py-1 px-1 mx-0.5 border border-dark-10"
        style={{ background: "#ECEDF0", borderRadius: "0.25rem" }}
      >
        {children}
      </span>
    ),
    highlight: ({ children }) => (
      <span className="bg-yellow-200 rounded-sm px-1">{children}</span>
    ),
    expandable: ({ mark, children }) => {
      return <Expandable mark={mark} children={children} />;
    },
    popover: ({ mark, children }) => (
      <VideoPopover
        iframe={mark.iframe}
        image={mark.mainImage}
        width={
          mark.mainImage
            ? Math.ceil(mark.mainImage.asset.metadata.dimensions.width / 2)
            : null
        }
        buttonText={mark.mainImage ? null : children}
        buttonBg={
          mark.mainImage
            ? null
            : mark.style
              ? BUTTON_BG_MAP.get(mark.style)
              : null
        }
      />
    ),
  },
  block(props) {
    const { node, children } = props;

    switch (node.style) {
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "homeHero":
      case "sectionTitle":
      case "tinyTitle":
      case "bigTitle":
        return <Heading {...props} />;

      case "li":
        return <h4>{children}</h4>;

      case "blockquote":
        return (
          <blockquote
            className={cn(
              "border-l-8 border-solid border-green-emerald my-6 p-6 text-lg leading-normal rounded-xl",
              style.gradientBG
            )}
          >
            {children}
          </blockquote>
        );

      case "pill":
        return <EyebrowPill node={node} children={children} />;

      // block, not inline
      case "code":
        return <CodeBlock node={node} children={children} />;

      // style not block or mark
      case "callout":
        return <div className="bg-dark-5 p-4 rounded-lg mb-6">{children}</div>;

      case "normal":
      default:
        return <SerializedComponent {...{ node, children }} />;
    }
  },
};

const SerializedComponent = ({ node, children }) => {
  switch (node._type) {
    case "mainImage":
      return <Figure node={node} />;

    case "inlineImage":
      return <Figure node={node.image} />;

    case "externalVideo":
      return <ExternalVideo node={node} />;

    case "arcadeEmbed":
      return <ArcadeEmbed url={node.url} />;

    case "callout":
    case "infoBox":
    case "inlineInfoBox":
      return <Callout node={node} children={children} />;

    case "hubspotEmbed":
      return (
        <Popover
          buttonText={node.buttonText}
          content={<HubspotWebform section={{ formId: node.formId }} />}
        />
      );

    case "customBlock":
      return <CustomBlocks node={node} />;

    case "workflowReference":
      return <InlineCardWorkflow node={node.workflow} />;

    case "playbookReference":
      return <InlineCardPlaybook node={node.playbook} />;

    case "quoteReference":
      return <InlineCardQuote node={node.quote} />;

    case "twitter":
      return <TwitterTweetEmbed tweetId={node.id} />;

    case "promo":
      return <PromoBlock node={node} className="mb-8" />;

    case "table":
    case "tableBlock":
      return <BlockTable node={node} />;

    case "anchor":
      return <Anchor node={node} />;

    case "callToAction":
      return <CallToAction action={node?.action} />;

    case "divider":
      return <Divider padding={node?.padding} />;

    case "productInterface":
      return <InlineCardProductInterface node={node} />;

    default:
      // homepage purple eyebrow
      return node?.children?.length > 0 &&
        node?.children[0]?.marks.includes("strong") &&
        node?.children[0]?.marks.includes("brandColor") ? (
        <div className="mb-1">{children}</div>
      ) : (
        <p>{children}</p>
      );
  }
};

const InlineCardProductInterface = ({ node }) => (
  <span className="bg-dark-2 py-0.5 px-2 mx-1 rounded-md border border-dark-10">
    {node.icon ? (
      <FontAwesomeIcon
        icon={iconSet[node.icon]}
        className="mr-1 text-secondary"
      />
    ) : null}
    {node.title}
  </span>
);

const Expandable = ({ mark, children }) => {
  const [toggled, setToggle] = useState();
  return (
    <span className="block mb-6 border border-dark-10 rounded-lg p-4 hover:border-lavender text-left bg-white">
      <div
        className="hover:text-link font-medium cursor-pointer text-lg"
        onClick={() => {
          setToggle(!toggled);
        }}
        role="button"
      >
        <TinyDownArrow
          rotate={toggled ? null : -90}
          className="mr-2 mt-2 float-left"
        />
        {mark.header}
      </div>
      {toggled && (
        <div className="text-base mt-2 leading-relaxed">{children}</div>
      )}
    </span>
  );
};

const Heading = ({ node, children }) => {
  const site = useContext(SiteContext);
  const dropAnchor = site?.doc._type === "documentation";

  switch (node.style) {
    case "h1":
      return (
        <h1 className="text-5xl md:text-6xl font-medium leading-none tracking-tight mb-8">
          {children}
        </h1>
      );

    case "h2":
      return (
        <h2 className="relative text-3xl md:text-4xl tracking-none mt-6 mb-4 first:mt-0">
          {dropAnchor ? (
            <a id={tocId(children[0])} className="absolute -top-6"></a>
          ) : null}
          {children}
        </h2>
      );

    case "h3":
      return (
        <h3 className="relative text-2xl leading-none sm:leading-tight font-base tracking-tight my-8 first:mt-0">
          {dropAnchor ? (
            <a id={tocId(children[0])} className="absolute -top-6"></a>
          ) : null}
          {children}
        </h3>
      );

    case "h4":
      return (
        <h4 className="text-xl font-semibold leading-snug tracking-tight mb-3 first:mt-0">
          {children}
        </h4>
      );

    case "h5":
      return <h2 className="font-medium sm:text-lg mb-2">{children}</h2>;

    case "homeHero":
      return (
        <h1 className="text-5xl sm:text-6xl md:text-7xl mt-2 font-bold leading-none tracking-tight">
          {children}
        </h1>
      );

    case "sectionTitle":
      return (
        <h4 className="text-xl-homepage font-bold leading-none sm:leading-tight tracking-tight mb-4">
          {children}
        </h4>
      );

    case "tinyTitle":
      return <h6 className="font-medium mt-8 mb-1 first:mt-0">{children}</h6>;

    case "bigTitle":
      return (
        <h2 className="text-2xl sm:text-xl-homepage lg:text-3xl xl:text-5xl tracking-none my-4 first:mt-0">
          {children}
        </h2>
      );
  }
};

const Anchor = ({ node }) => {
  const site = useContext(SiteContext);
  const path =
    site?.doc._type == "post"
      ? textToSlug(node.label)
      : `anchors--${node._key}`;

  return (
    <div className="relative">
      <div className="first:hidden">
        {site?.doc._type !== "post" &&
          ![
            "Overview",
            "Introduction",
            "Getting started",
            "Prompt - copy/paste",
          ].includes(node.label) ? (
          <Divider className="my-16" />
        ) : null}
      </div>
      <a id={path} className="absolute -top-16"></a>
    </div>
  );
};

export const EyebrowPill = ({ children, node, className }) => {
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);
  const isSpecial = ["product/ai", "product/ai-new"].includes(
    site.doc?.slug?.current
  );

  return (
    <div
      className={cn(
        "mb-3 inline-block rounded-full font-medium text-sm sm:text-base",
        site.isDarkBg || section?.isDarkBg
          ? "text-white border-white"
          : "text-white bg-link",
        isSpecial ? "p-2" : "py-2 px-2.5 sm:px-3.5 md:px-5 border",
        className
      )}
      style={
        isSpecial
          ? {
            backgroundImage: "url(/static/img/eyebrow-pill-bg.png)",
            backgroundSize: "277px 173px",
            backgroundPosition: "left -7px top -20px",
            boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.25)",
          }
          : null
      }
    >
      <div
        className={isSpecial ? "rounded-full p-2.5 px-5 text-white" : null}
        style={
          isSpecial
            ? {
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.00) 100%), #2D2D2D",
            }
            : null
        }
      >
        {children}
      </div>
    </div>
  );
};

const CodeBlock = ({ node, children }) => {
  return (
    <div className="max-w-md md:max-w-lg lg:max-w-2xl">
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        customStyle={{
          fontSize: "14px",
          borderRadius: "0.5rem",
          padding: "1rem",
          marginBottom: "1.5rem",
          overflow: "scroll",
        }}
      >
        {node.children[0].text}
      </SyntaxHighlighter>
    </div>
  );
};
