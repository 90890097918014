import React from "react";
import getYouTubeId from "get-youtube-id";
import { VideoPopover } from "./video-popover";
import { imageBuilder } from "../lib/helpers";

export const ExternalVideo = ({ node }) =>
  node.url ? (
    <div>
      <YouTubePlayer url={node.url} />
    </div>
  ) : node.vimeoId || node.loomUrl ? (
    <div className="justify-center my-8">
      <VideoPopover
        image={node.previewImage}
        previewVideo={node.previewVideo}
        mobileImage={
          node?.previewImage
            ? imageBuilder(node.previewImage).quality(99).auto("format").url()
            : null
        }
        width={Math.ceil(
          node.previewImage?.asset.metadata.dimensions.width / 2
        )}
        height={Math.ceil(
          node.previewImage?.asset.metadata.dimensions.height / 2
        )}
        vimeoId={node.vimeoId}
        loomUrl={node.loomUrl}
        buttonText={node.previewCta}
        buttonBg={node.previewCta ? "bg-transparent" : null}
        buttonClass={node.previewCta ? "text-white border-white" : null}
      />
    </div>
  ) : null;

// @todo remove existing react-youtube dependency
export const YouTubePlayer = ({ url }) => (
  <div
    className="video"
    style={{
      overflow: "hidden",
      position: "relative",
      paddingBottom: "56.25%",
      paddingTop: 30,
      marginBottom: "1rem",
    }}
  >
    <iframe
      src={`https://www.youtube.com/embed/${getYouTubeId(url)}`}
      allow="accelerometer; autoplay; encrypted-media; gyroscope;"
      allowFullScreen
      width="1024"
      height="576"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        aspectRatio: "16 / 9",
        border: 0,
      }}
    ></iframe>
  </div>
);
