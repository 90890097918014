import React, { useContext, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cn, isThinPage, mapEdgesToNodes } from "../../lib/helpers";
import { pageDocPath, trackPath } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas as iconSet } from "@fortawesome/free-solid-svg-icons";
import { TinyDownArrow } from "../icon/tiny-down-arrow";
import { Logo } from "../icon/logo";
import { SiteContext } from "./site-context";
import Container from "./container";
import { useHoverIntent } from "react-use-hoverintent";
import { TinyPills } from "../card/card";
import { CardContext } from "../card/context";
import { PublishDate } from "../card/publish-date";
import {
  ATTRIBUTION_PARAM,
  CollapsibleMenu,
  DEMO_PHRASE,
  DEMO_URL,
  LOGIN_PHRASE,
  LOGIN_URL,
} from "../sections/snowflake/navigation";
import { DEMO_PAGES } from "../sections/snowflake/get-started-banner";
import { DARK_BACKGROUNDS } from "./layout";

const TOP_LEVEL_PATHS = [
  "customers", "pricing", "integrations", "careers", "community",
  "resources", "security", "resources/signals", "prompts", "podcast", "events"
];
const OVERLAY_TYPES = ["offering", "account", "competitor", "story", "playbook", "signal", "prompt", "asset"];

// text or border color map from theme colors
export const colorMap = new Map([
  ["mint", "#098a44"],
  ["peach", "#D0411E"],
  ["lime", "#567F14"],
  ["robin", "#186EBE"],
  ["purple-default", "#723EC7"],
  ["default", "#888"],
  ["lemon", "#78770b"],
  ["melon", "#b72329"],
  ["secondary", "#FFFFFF"],
  ["auxiliary", "#111111"],
  ["dark-10", "#333333"],
  ["dark-20", "#555555"],
]);

const query = graphql`
  query GlobalNavQuery {
    menus: allSanityMenu(
      filter: { _id: { eq: "586903f3-aa9a-4a8a-b57c-537d40c1fcc4" } }
    ) {
      edges {
        node {
          items {
            _key
            title
            description
            reference {
              ... on SanityPage {
                _id
                _type
                title
                slug {
                  current
                }
              } 
              ... on SanityOffering {
                _type
                title
                slug {
                  current
                }
              }
            }
            href
            icon
            bottomBorder
            children {
              _key
              title
              description
              reference {
                ... on SanityPage {
                  _id
                  _type
                  title
                  slug {
                    current
                  }
                } 
                ... on SanityOffering {
                  _type
                  title
                  slug {
                    current
                  }
                }
              }
              href
              icon
              bottomBorder
              color
              children {
                _key
                title
                description
                reference {
                  ... on SanityPage {
                    _id
                    _type
                    title
                    slug {
                      current
                    }
                  } 
                  ... on SanityOffering {
                    _type
                    title
                    slug {
                      current
                    }
                  }
                }
                href
                icon
                bottomBorder
              }
            }
          }
        }
      }
    }
    stories: allSanityStory(
      limit: 1
      sort: { fields: [_createdAt], order: DESC }
      filter: { status: { eq: "published" }, isFeatured: { eq: true } }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
    posts: allSanityPost(
      limit: 1
      sort: { fields: [publishedAt], order: [DESC] }
      filter: { isPublished: { eq: true }, isFeatured: { eq: true } }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

const makeUrl = (item) =>
  item.href ? item.href : item.reference ? pageDocPath(item.reference) : null;

export const DOCS_PATHS = ["/help/"];

export const GlobalNav = () => {
  const site = useContext(SiteContext);
  const [isCollapsed, setCollapsed] = useState(true);

  const data = useStaticQuery(query) || {};
  const menus = mapEdgesToNodes(data.menus);
  const content = mapEdgesToNodes(data.stories).concat(
    mapEdgesToNodes(data.posts)
  );

  const path = pageDocPath(site.doc);
  const slimLayout = isThinPage(site);
  const isDocs =
    site?.doc._type === "documentation" || DOCS_PATHS.includes(path);
  const isLoginNavPage = site.isHomepage || isDocs;
  const hasBanner = site.banners.length > 0;
  const nonSticky = site.hideNav || isDocs;
  const transparent = (
    site.isHomepage || TOP_LEVEL_PATHS.includes(site?.doc?.slug?.current) || OVERLAY_TYPES.includes(site?.doc._type)
  ) && site.isPageTop;
  const darkBg = DARK_BACKGROUNDS.includes(site?.doc?.sections?.[0]?.background)
    || site.isDarkBg && !site?.doc?.sections?.[0]?.background;

  return (
    <>
      <div
        className={cn(
          "z-100 w-full text-sm top-0 transition-colors duration-200",
          site.hideNav ? null : transparent ? "bg-transparent" : "bg-white",
          // non-sticky
          !site.hideNav && !nonSticky
            ? site.isPageTop
              ? hasBanner
                ? "relative"
                : "absolute"
              : hasBanner
                ? "sticky"
                : "sticky h-0"
            : "absolute"
        )}
      >
        <div
          className={cn(
            "relative",
            site.hideNav || transparent ? null : "border-b border-dark-10"
          )}
        >
          {/* faux background element so menu height can be zero for smooth scroll */}
          {!site.hideNav && (
            <div
              className={cn(
                "absolute -top-px right-0 bottom-0 left-0 transition-colors duration-200",
                transparent ? "bg-transparent" : "bg-white"
              )}
            ></div>
          )}
          <Container
            maxWidth={slimLayout ? "max-w-screen-lg" : "max-w-screen-xl"}
            className="flex justify-between items-center mx-auto w-full relative p-3"
          >
            <a href="/" className={transparent && darkBg ? "text-white" : null}>
              {site.hideNav ? (
                <Logo
                  className={cn("h-6", darkBg ? "text-white" : null)}
                />
              ) : (
                <>
                  <Logo
                    iconOnly={true}
                    className="hidden md:block lg:hidden h-6"
                  />
                  <Logo className="h-6 md:hidden lg:block" />
                </>
              )}
            </a>
            {!site.hideNav ? (
              <ul className="hidden md:flex items-center z-100 relative">
                {menus[0].items.map((parent) => (
                  <MenuItem
                    key={parent._key}
                    menu={parent}
                    content={content}
                    transparent={transparent}
                    darkBg={darkBg}
                  />
                ))}
              </ul>
            ) : null}
            <div className="hidden md:flex text-secondary text-sm">
              <LoginMenu
                isLoginNavPage={isLoginNavPage}
                nonSticky={nonSticky}
                transparent={transparent}
                darkBg={darkBg}
              />
            </div>
            <CollapsibleMenu
              className="md:hidden ml-2"
              isMainNav={true}
              isCollapsed={isCollapsed}
              setCollapsed={setCollapsed}
            />
            <div className="absolute" style={{ zIndex: 999 }}>
              {isCollapsed}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

const MenuItem = ({ menu, content, transparent, darkBg }) => {
  const site = useContext(SiteContext);
  const url = makeUrl(menu);
  const [isItemHover, itemRef] = useHoverIntent({ timeout: 150 });

  return (
    <li key={menu._key} ref={itemRef}>
      <a
        href={url}
        className={cn(
          "flex items-center px-3 py-2 font-medium hover:bg-dark-5 rounded-lg cursor-pointer",
          transparent && darkBg ? "text-white" : null,
          url && transparent && darkBg ? "hover:bg-light-20" : null
        )}
      >
        {menu.title || menu.reference?.title}
        {menu.children.length > 0 && (
          <TinyDownArrow
            className={cn(
              "ml-1",
              transparent && darkBg ? "text-white" : "text-auxiliary"
            )}
          />
        )}
      </a>
      {menu.children.length > 0 && (
        <div
          className={cn(
            "absolute tranform transition-opacity duration-200",
            isItemHover ? "opacity-100" : "opacity-0 pointer-events-none"
          )}
        >
          <div className="pt-7 relative">
            <div
              className="absolute top-5 left-10 transform rotate-45 rounded-sm bg-white h-5 w-5"
              style={{ boxShadow: "-1px -1px 6px -1px rgba(0,0,0,0.1)" }}
            ></div>
            <PopoverMenu
              menu={menu}
              hasSubMenus={menu.children[0].children.length > 0}
              showContent={
                menu.title === "Resources" ||
                menu.reference?.title === "Resources"
              }
              content={content}
            />
          </div>
        </div>
      )}
    </li>
  );
};

const PopoverMenu = ({ menu, hasSubMenus, showContent, content }) => (
  <div
    className={cn("relative bg-white rounded-xl", showContent ? "flex" : null)}
    style={{ boxShadow: "0 16px 24px rgba(0,0,0,0.12)" }}
  >
    <ul
      className={cn("p-3", hasSubMenus ? "flex flex-wrap gap-4" : null)}
      style={
        hasSubMenus
          ? {
            width: "46.5rem",
          }
          : null
      }
    >
      {menu.children.map((child) => {
        const childUrl = makeUrl(child);
        return (
          <React.Fragment key={child._key}>
            <li
              className="relative"
              style={hasSubMenus ? { width: "22rem" } : null}
            >
              <a
                href={childUrl}
                className={cn(
                  "inline-block p-2 flex items-center rounded-lg group whitespace-nowrap",
                  childUrl ? "hover:bg-dark-2" : null,
                  hasSubMenus ? null : "pr-20 hover:text-black"
                )}
              >
                <div
                  className={cn(
                    "mr-3",
                    hasSubMenus
                      ? cn("p-2 rounded-md", `bg-${child.color || "dark-10"}`)
                      : null
                  )}
                >
                  {iconSet[child.icon] && (
                    <div className="relative h-5 w-5 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={iconSet[child.icon]}
                        className={
                          hasSubMenus
                            ? "text-secondary h-3.5"
                            : "group-hover:text-link text-dark-40"
                        }
                        style={
                          hasSubMenus
                            ? {
                              color:
                                colorMap.get(child.color) ||
                                colorMap.get("default"),
                            }
                            : null
                        }
                      />
                    </div>
                  )}
                </div>
                <div>
                  <p className="font-semibold">
                    {child.title || child.reference.title}
                  </p>
                  {child.description && (
                    <p className="mt-1 text-sm hidden md:block text-dark-60 group-hover:text-dark-70 whitespace-nowrap">
                      {child.description}
                    </p>
                  )}
                </div>
              </a>
              <SubMenu
                menu={child}
                className="ml-4 mt-1"
                style={hasSubMenus ? { fontSize: 13 } : null}
              />
            </li>
            {child.bottomBorder ? (
              <li className="border-b border-dark-5 w-full"></li>
            ) : null}
          </React.Fragment>
        );
      })}
    </ul>
    {showContent && <FeaturedContent content={content} />}
  </div>
);

const SubMenu = ({ menu, className, style }) =>
  menu.children.length > 0 && (
    <ul className={className} style={style}>
      {menu.children.map((child) => {
        const childUrl = makeUrl(child);
        return (
          <li key={child._key}>
            <a
              href={childUrl}
              className={cn(
                "inline-block px-2 py-2 group flex items-center rounded-lg",
                childUrl ? "hover:text-black hover:bg-dark-2" : null
              )}
            >
              <span className="w-6 mr-1 flex justify-center">
                {iconSet[child.icon] && (
                  <FontAwesomeIcon
                    icon={iconSet[child.icon]}
                    className="h-3 text-secondary group-hover:text-link"
                  />
                )}
              </span>
              <span className="flex-grow">
                {child.title || child.reference.title}
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  );

const LoginMenu = ({ isLoginNavPage, nonSticky, transparent, darkBg }) => {
  const site = useContext(SiteContext);
  const path = trackPath(site?.doc);
  const matchPath = pageDocPath(site?.doc);
  const isDemo = DEMO_PAGES.includes(matchPath);

  const menu = []
    .concat([
      {
        title: LOGIN_PHRASE,
        href: LOGIN_URL,
        className: cn(
          "border",
          transparent
            ? "border-white text-light-80 hover:bg-light-20 hover:text-white"
            : "border-black hover:bg-dark-5 hover:text-black"
        ),
        hide: true,
      },
    ])
    .concat(
      //!isDemo && !nonSticky
      !isDemo
        ? [
          {
            title: DEMO_PHRASE,
            href: `${DEMO_URL}?${ATTRIBUTION_PARAM}=navigation--${path}`,
            className: cn(
              "border text-black",
              transparent
                ? darkBg ? "bg-white border-white hover:bg-light-80" : "bg-link text-white"
                : "bg-link text-white",
              // @todo use background color to pick this
              transparent && site.isHomepage ? "text-link" : null
            ),
            // reveal: true,
            onClick: () => {
              site.metrics.logEvent({
                label: DEMO_PHRASE.toLowerCase(),
                category: "button_click",
                action: "nav-contact",
              });
            },
          },
        ]
        : []
    );
  // .concat([
  //   {
  //     title: SIGNUP_PHRASE,
  //     href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=navigation--${path}`,
  //     className: cn(
  //       "border",
  //       transparent
  //         ? darkBg ? "bg-white border-white text-primary hover:bg-light-80" : "bg-black text-white hover:bg-dark-80"
  //         : "bg-black border-black text-white hover:bg-dark-80",
  //       // @todo use background color to pick this
  //       transparent && site.isHomepage ? "text-link" : null
  //     ),
  //     onClick: () => {
  //       site.metrics.logEvent({
  //         label: SIGNUP_PHRASE.toLowerCase(),
  //         category: "button_click",
  //         action: "nav-signup",
  //       });
  //     },
  //   },
  // ]);

  return (
    <ul className="flex items-center font-medium gap-2">
      {menu.map((item, i) => {
        // so many scenarios
        const showLink =
          (nonSticky &&
            ((!site.hideNav && !item.reveal) ||
              (site.hideNav && !item.hide))) ||
          (!nonSticky &&
            ((!isLoginNavPage && !item.hide) ||
              (isLoginNavPage && site.isPageTop && !item.reveal) ||
              (isLoginNavPage && !site.isPageTop && !item.hide)));

        return showLink ? (
          <li key={i} className="flex justify-end" style={{ minWidth: "7rem" }}>
            <a
              href={item.href}
              className={cn(
                "inline-block px-3 py-2 font-medium rounded-lg",
                site.hideNav
                  ? darkBg
                    ? "text-white hover:bg-white hover:text-link"
                    : "text-link hover:bg-dark-10"
                  : item.className
              )}
              onClick={item.onClick}
            >
              {item.title}
            </a>
          </li>
        ) : null;
      })}
    </ul>
  );
};

const FeaturedContent = ({ content }) => (
  <div className="bg-dark-2 border-l border-dark-10 py-5 px-4 rounded-r-xl w-64">
    <p className="text-auxiliary font-medium">Featured</p>
    {content.map((n) => (
      <CardContext.Provider value={n} key={n._id}>
        <a
          href={pageDocPath(n)}
          className="block border border-dark-10 bg-white p-3 rounded-lg mt-4 hover:text-black hover:bg-light-2 hover:border-link"
          key={n._id}
        >
          <TinyPills list={[n._type]} />
          <p className="my-1 font-medium leading-normal">{n.title}</p>
          <p className="text-auxiliary text-xs font-medium">
            <PublishDate />
          </p>
        </a>
      </CardContext.Provider>
    ))}
  </div>
);
