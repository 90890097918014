import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { QuotePanels } from "../quote-panels";
import { Twitter } from "../../icon/twitter";
import { LinkedIn } from "../../icon/linkedin";
import { Button } from "../../button";
import { JOIN_SLACK_PATH, LINKEDIN_URL, TWITTER_URL } from "./channels-buttons";
import { SiteContext } from "../../global/site-context";
import { Slack } from "../../icon/slack";
import { SectionContext } from "../context";

// @todo rename to social posts

const DEFAULT_QUERY_RESULTS = "docs";

export const tweetsQuery = graphql`
  query TweetsQuery {
    docs: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        # keep multiple lines
        _rawExcerpt: { ne: null }
        url: { ne: null }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    uncommon: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        _rawExcerpt: { ne: null }
        url: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["5a3d3357-3b24-4764-bf83-01a4fb40f5c4"] } }
        }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
  }
`;

export const Tweets = ({ section }) => {
  const site = useContext(SiteContext);
  const sectionContext = useContext(SectionContext);
  // @todo unify with quoteables.js and logos.js
  const results = useStaticQuery(tweetsQuery) || {};
  const desired = results[section?.category || DEFAULT_QUERY_RESULTS];

  const docList = desired?.edges.length
    ? desired
    : results[DEFAULT_QUERY_RESULTS].edges.length
    ? results[DEFAULT_QUERY_RESULTS]
    : [];

  const nodes = mapEdgesToNodes(docList);
  const docs =
    section?.cards && nodes.length > 0 ? nodes.slice(0, section.cards) : nodes;

  return (
    <>
      <QuotePanels quotes={docs} />
      <div className="flex flex-col sm:flex-row justify-center mt-12">
        <Button
          text="common-room-hq"
          bgColor={
            sectionContext.isDarkBg ? "bg-light-10" : "bg-brand-linkedin"
          }
          icon={<LinkedIn size={18} />}
          isSmall={true}
          href={LINKEDIN_URL}
          attributes={{ target: "_blank" }}
        />
        <Button
          text="@CommonRoomHQ"
          bgColor={
            sectionContext?.isDarkBg ? "bg-light-10" : "bg-brand-twitter"
          }
          icon={<Twitter size={18} />}
          isSmall={true}
          href={TWITTER_URL}
          attributes={{ target: "_blank" }}
          className="my-2 sm:mx-2 sm:my-0"
        />
        {section?.category !== "uncommon" && (
          <Button
            text="Join Slack community"
            bgColor={sectionContext.isDarkBg ? "bg-light-10" : "bg-brand-slack"}
            icon={<Slack size={18} />}
            isSmall={true}
            href={JOIN_SLACK_PATH}
            onClick={() => {
              site.metrics.logClick("join-slack", "quote-wall");
            }}
          />
        )}
      </div>
    </>
  );
};
