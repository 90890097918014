import React from "react";
import { ImageBuilder } from "../../global/image-builder";
import { cn } from "../../../lib/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAmazon,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faHeart,
  faMicrophone,
  faPodcast,
} from "@fortawesome/free-solid-svg-icons";

// @todo site settings
export const SPOTIFY_SHOW_URL =
  "https://open.spotify.com/show/2lqQ5xDkwo56hGxDRIwbIU?si=O2kWLpItRn-9fkwHQz5iHg";

export const APPLE_SHOW_URL =
  "https://podcasts.apple.com/us/podcast/go-to-market-mavericks/id1774317155";

const YOUTUBE_SHOW_URL =
  "https://www.youtube.com/playlist?list=PLKDS0si7azFltUVuYOUW1aby9NVevUsA1";

const POCKET_CAST_URL = "https://pca.st/ebws6x13";

const IHEART_SHOW_URL =
  "https://www.iheart.com/podcast/269-go-to-market-mavericks-258089390/";

const AMAZON_SHOW_URL =
  "https://music.amazon.com/podcasts/11d9391d-b1a1-4778-b46e-e13bb69189eb/go-to-market-mavericks";

export const Podcast = () => (
  <>
    <iframe
      height="450"
      width="100%"
      title="Media player"
      src="https://embed.podcasts.apple.com/us/podcast/go-to-market-mavericks/id1774317155?itscg=30200&amp;itsct=podcast_box_player&amp;ls=1&amp;mttnsubad=1774317155&amp;theme=light"
      id="embedPlayer"
      style={{
        border: 0,
        borderRadius: 12,
        width: "100%",
        height: 450,
        maxWidth: 660,
        marginBottom: "2rem",
      }}
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
      allow="autoplay *; encrypted-media *; clipboard-write"
    ></iframe>
    <PodcastLinks className="mt-6" />
  </>
);

const linkCSS =
  "transform hover:scale-105 transition-transform p-2 md:font-semibold flex items-center";
const ICON_SIZE = "xl";

export const PodcastLinks = ({ className, hideYouTube, path }) => (
  <div
    className={cn(
      "flex gap-4 items-center text-sm",
      className,
      hideYouTube ? "justify-around" : "justify-center"
    )}
  >
    <a
      href={APPLE_SHOW_URL}
      target="_blank"
      className={cn(linkCSS, "hover:text-brand-applepodcasts")}
    >
      <FontAwesomeIcon
        icon={faPodcast}
        size={ICON_SIZE}
        className="mr-1 max-h-6"
      />
      Apple Podcasts
    </a>
    <a
      href={SPOTIFY_SHOW_URL}
      target="_blank"
      className={cn(linkCSS, "hover:text-brand-spotify")}
    >
      <FontAwesomeIcon
        icon={faSpotify}
        size={ICON_SIZE}
        className="mr-1 max-h-6"
      />
      Spotify
    </a>
    {path === "podcast/all" && (
      <>
        <a
          href={IHEART_SHOW_URL}
          target="_blank"
          className={cn(linkCSS, "hover:text-brand-youtube")}
        >
          <FontAwesomeIcon
            icon={faHeart}
            size={ICON_SIZE}
            className="mr-1 max-h-6"
          />
          iHeartRadio
        </a>
        <a
          href={POCKET_CAST_URL}
          target="_blank"
          className={cn(linkCSS, "hover:text-brand-pocketcasts")}
        >
          <FontAwesomeIcon
            icon={faMicrophone}
            size={ICON_SIZE}
            className="mr-1 max-h-6"
          />
          Pocket Casts
        </a>
      </>
    )}
    <a
      href={AMAZON_SHOW_URL}
      target="_blank"
      className={cn(linkCSS, "hover:text-brand-amazon")}
    >
      <FontAwesomeIcon
        icon={faAmazon}
        size={ICON_SIZE}
        className="mr-1 max-h-6"
      />
      Amazon Music
    </a>
    {!hideYouTube && (
      <a
        href={YOUTUBE_SHOW_URL}
        target="_blank"
        className={cn(linkCSS, "hover:text-brand-youtube")}
      >
        <FontAwesomeIcon
          icon={faYoutube}
          size={ICON_SIZE}
          className="mr-1 max-h-6"
        />
        YouTube
      </a>
    )}
  </div>
);
