import React, { useContext } from "react";
import { cn, imageBuilder } from "../../lib/helpers";
import Container from "../global/container";
import { GraphicBackground } from "../global/graphic-background";
import { SiteContext } from "../global/site-context";
import PortableText from "../portableText";
import { SectionContext } from "./context";
import { Image } from "./featured-media";
import { Logo } from "../icon/logo";
import tailwindConfig from "../../../tailwind.config";
import { SparklesIconOnly } from "../icon/sparkles-only";

import * as styles from "../global/global.module.css";
import * as portableTextStyles from "../portableText.module.css";

const PILL_HEADER_TYPES = [
  "offering", "competitor", "prompt", "playbook", "signal", "post", "story", "asset"
];

export const CONTAINER_MAP = new Map([
  ["narrow", "max-w-screen-sm"],
  ["thin", "max-w-screen-md"],
  ["large", "max-w-screen-lg"],
  ["full", "max-w-screen-boundary"],
]);

const PADDING_MAP = new Map([
  ["default", "py-16 md:py-24"],
  ["none", ""],
  ["tight", "py-8"],
  ["top", "pt-16 md:pt-24"],
  ["bottom", "pb-16 md:pb-24"],
  ["bottomExtra", "pt-16 md:pt-24 pb-32 md:pb-44"],
  ["topOverlap", "pb-16 md:pb-24 -mt-20 md:-mt-28"],
  // one-off
  ["topOverlapLarge", "pb-16 md:pb-24 -mt-32 md:-mt-44"],
  ["bottomExtraLarge", "pt-16 md:pt-24 pb-36 md:pb-52"],
  ["topOverlapNone", "-mt-12 md:-mt-16"],
]);

const COLOR_MAP = new Map([
  ["blackGlow", "bg-gray-1000"],
  ["yellow", "bg-yellow-200"],
  ["gray", "bg-gray-200"],
  ["blue", "bg-blue-100"],
  ["white", "bg-white"],
  ["lavenderUltraLight", "bg-green-ultralight"],
  ["dots", "bg-dark-5"],
  ["dots-lavender", "bg-lavender"],
  ["dots-peach", "bg-peach"],
  ["dots-mint", "bg-mint"],
  ["dots-robin", "bg-robin"],
  ["dots-lemon", "bg-lemon"],
  ["gradient", styles.gradientHero],
  ["gradientLavender", styles.gradientLavender],
  ["gradientOnly", styles.gradientBG],
  // ["gradientLightBox", styles.gradientBG],
  // ["gradientBox", styles.gradientBox],
  ["gradientDark", styles.gradientDark],
  ["mint", "bg-green-darkPageBg"],
  ["innerDark", "bg-transparent"],
]);

const GRADIENT_BOX_MAP = new Map([
  ["gradientBox", {}],
  ["gradientLightBox", {}],
  [
    "gradientSpotlights",
    {
      background:
        "url(/static/img/background-spotlights.svg) no-repeat center center/cover",
    },
  ],
  [
    "gradientSpotlightsSpiral",
    {
      background:
        "url(/static/img/spotlights-spiral.svg) no-repeat center center/cover",
    },
  ],
]);

const GRADIENT_BACKGROUNDS = ["gradient", "gradientOnly", "gradientDark"];

// values in content, do not change to match tailwind
const DARK_BACKGROUNDS = [
  "black",
  "gray-900",
  "blackGlow",
  "mint",
  "innerDark",
];

const GLOW_BACKGROUNDS = ["blackGlow", "whiteGlow"];

const LEGACY_BACKGROUNDS = [
  "dots",
  "dots-lavender",
  "dots-peach",
  "lime",
  "dots-mint",
  "dots-robin",
  "dots-lemon",
];

export const SectionWrapper = (props) => {
  const {
    anchor,
    children,
    background,
    padding,
    container,
    name,
    layout,
    noHeaders,
    desktopOnly,
    isColumn,
    effect,
    backgroundCentered,
  } = props;

  const site = useContext(SiteContext);
  const sectionAbove = useContext(SectionContext);
  const mainImage = props.image || props.mainImage;
  const isHeader = name === "header";
  const bgWithDefault = name === "getStarted" ? "mint" : background;

  const bg = name === "getStarted" && !bgWithDefault ? "mint" : bgWithDefault
    ? COLOR_MAP.has(bgWithDefault)
      ? COLOR_MAP.get(bgWithDefault)
      : bgWithDefault
    : "";

  const paddingClass = PADDING_MAP.has(padding)
    ? PADDING_MAP.get(padding)
    : PADDING_MAP.get("default");
  const fullHeight = padding === "full";
  const heightClass = fullHeight ? "min-h-screen grid place-items-center" : "";

  const bgStyle =
    isHeader && LEGACY_BACKGROUNDS.includes(bgWithDefault)
      ? {
        backgroundImage: `url('/static/img/tile.svg')`,
        backgroundSize: "12px 12px",
      }
      : bgWithDefault === "mint"
        ? {
          backgroundImage: `url('/static/img/emerald-bg.png')`,
          backgroundBlendMode: "hue",
          backgroundSize: "cover",
          backgroundPosition: site.isLarge ? "center center" : "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundColor: tailwindConfig.theme.colors.green.emerald,
        }
        : null;

  const sectionAboveLightBg = sectionAbove?.background && !DARK_BACKGROUNDS.includes(sectionAbove?.background)
  const sectionIsDarkBg =
    DARK_BACKGROUNDS.includes(bgWithDefault) ||
    ((site.isDarkBg || sectionAbove?.isDarkBg) && !bg && !sectionAboveLightBg);
  const isGlow = GLOW_BACKGROUNDS.includes(bgWithDefault);
  const isGradient = GRADIENT_BACKGROUNDS.includes(bgWithDefault);
  const isGradientBox = GRADIENT_BOX_MAP.has(bgWithDefault);
  const isSvg = mainImage?.asset?.extension === "svg";
  const isBgOverlap = mainImage?.layout === "overlap";

  return (
    <SectionContext.Provider
      value={{
        ...props,
        isDarkBg: sectionIsDarkBg,
        container,
        isColumn,
      }}
    >
      <div
        id={anchor}
        className={cn(
          "relative",
          paddingClass,
          heightClass,
          ["carouselCards", "carouselCardsHero"].includes(layout) ? "overflow-hidden" : null,
          desktopOnly ? "hidden sm:block" : null,
          bgWithDefault === "black" ? "bg-gray-1000" : bgWithDefault ? bg : "",
          sectionIsDarkBg ? "text-white" : "",
          ["gradientOnly", "gradientDark"].includes(bgWithDefault) &&
            !["mediaRight", "centered"].includes(layout)
            ? "md:pb-36"
            : null
        )}
      >
        {bgStyle ? (
          <>
            <div
              className="absolute top-0 right-0 bottom-0 left-0"
              style={{ ...bgStyle }}
            ></div>
            {layout === "mediaRight" ? null : mainImage ? (
              <Container maxWidth={CONTAINER_MAP.get(container) || null}>
                <div
                  className={cn(
                    "hidden md:block absolute top-0 right-0 bottom-0 left-0 bg-no-repeat",
                    layout === "mediaTop"
                      ? "bg-top"
                      : backgroundCentered || layout === "mediaCentered"
                        ? "bg-center mt-12"
                        : ["narrow", "thin", "large"].includes(container)
                          ? "bg-left mt-12"
                          : layout === "mediaSmall"
                            ? "bg-center bg-contain mt-24 mb-12"
                            : "bg-left-bottom xl:bg-bottom",

                    effect !== "none" ? styles.pulseGrow : null
                  )}
                  style={{
                    backgroundImage: !isSvg
                      ? `url("${imageBuilder(mainImage)
                        .width(
                          Math.round(
                            mainImage.asset.metadata.dimensions.width
                          )
                        )
                        .height(
                          Math.round(
                            mainImage.asset.metadata.dimensions.height
                          )
                        )
                        .quality(100)
                        .auto("format")
                        .url()}")`
                      : `url("${imageBuilder(mainImage)
                        .quality(100)
                        .auto("format")
                        .url()}")`,
                    marginLeft: "50%",
                    backgroundSize: !isSvg
                      ? `${mainImage.asset.metadata.dimensions.width / 2}px`
                      : null,
                  }}
                ></div>
              </Container>
            ) : (
              <GraphicBackground />
            )}
          </>
        ) : isGlow ? (
          <>
            <div
              className={cn(
                "absolute -top-80 left-0 bottom-0 w-2/3 bg-gradient-radial from-spotlight-robin via-transparent to-transparent",
                bgWithDefault === "whiteGlow" ? "opacity-30" : "opacity-20"
              )}
            ></div>
            <div
              className={cn(
                "absolute top-0 bottom-0 right-0 w-2/3 bg-gradient-radial from-spotlight-salmon via-transparent to-transparent",
                bgWithDefault === "whiteGlow" ? "opacity-30" : "opacity-20"
              )}
            ></div>
          </>
        ) : isGradient ? (
          <div className="relative">
            {["gradientDark"].includes(bgWithDefault) && (
              <div
                className={cn(
                  "absolute left-0 top-24",
                  bgWithDefault === "gradient" ? "opacity-80" : "opacity-30"
                )}
              >
                <Image
                  src="/static/img/dot-circle.svg"
                  alt="background dots"
                  width={800}
                />
              </div>
            )}
            {layout === "mediaRight" ? null : (
              <div
                className={cn(
                  "relative mx-auto hidden",
                  isBgOverlap
                    ? "max-w-screen-max xl:block"
                    : cn("md:block", CONTAINER_MAP.get(container))
                )}
              >
                <Image
                  image={mainImage}
                  width={
                    isBgOverlap
                      ? mainImage.asset.metadata.dimensions.width
                      : 500
                  }
                  className={cn(
                    "absolute",
                    isBgOverlap ? "-top-44 left-0 w-full" : "top-0 right-0"
                  )}
                />
              </div>
            )}
          </div>
        ) : null}
        <Container
          className={cn(
            "relative",
            bgWithDefault === "gradientDark" ? "text-white" : null,
            isGradientBox
              ? "overflow-hidden p-12 lg:px-12 xl:px-12 max:px-12 lg:rounded-xl lg:border lg:border-dark-10"
              : null,
            bgWithDefault === "gradientLightBox"
              ? "p-6 lg:px-6 xl:px-6 max:px-6 rounded-xl border border-dark-10"
              : null
          )}
          maxWidth={
            // @todo go update widths on pages in CMS
            name === "resourcesSuggestions" &&
              ["guide", "video"].includes(site.doc.contentType)
              ? CONTAINER_MAP.get("large")
              : // use page-width for full-width section header, should be used wider
              container === "full" && name === "signalSources"
                ? CONTAINER_MAP.get(site?.doc.pageWidth)
                : CONTAINER_MAP.get(container) || null
          }
          style={isGradientBox ? GRADIENT_BOX_MAP.get(bgWithDefault) : null}
        >
          {bgWithDefault === "gradientBox" && (
            <Logo
              iconOnly={true}
              className="w-64 absolute -top-20 -left-12 mix-blend-overlay"
            />
          )}
          {!noHeaders && (
            <SectionHeaders {...props} isDarkBg={sectionIsDarkBg} />
          )}
          {children}
        </Container>
      </div>
    </SectionContext.Provider>
  );
};

export const SectionHeader = ({ children }) => (
  <h3 className="text-lg uppercase font-black mb-4">{children}</h3>
);

const layoutMap = new Map();
layoutMap.set("centered", "text-center mx-auto md:w-3/4");
layoutMap.set("constrainedLeft", "mr-auto md:w-2/3");

export const SectionHeaders = (props) => {
  const {
    layout,
    header,
    _rawSummary,
    padding,
    _type,
    name,
    isTightHeaders,
    isColumn,
    isDarkBg,
  } = props;
  const site = useContext(SiteContext);
  const tightenHeader =
    isTightHeaders ||
    padding === "tight" ||
    ["subscribe", "comment", "tags"].includes(name);
  const isPillHeader = site.isHomepage || PILL_HEADER_TYPES.includes(site?.doc._type);

  return header || _rawSummary ? (
    <div
      className={cn(
        layoutMap.has(layout) ? layoutMap.get(layout) : "",
        padding === "none" ? "" : tightenHeader ? "pb-4" : layout !== "carouselCardsHero" ? "pb-10" : null
      )}
    >
      {
        header ? isPillHeader ? (
          <PillHeader {...{ ...props, isDarkBg }} />
        ) : (
          <h3
            className={cn(
              header.length > 48 ? "w-5/6 mx-auto" : null,
              "text-xl-homepage font-semibold leading-none sm:leading-tight tracking-tight",
              _type === "webForm" ? layoutMap.get("centered") : null
            )}
          >
            {header}
          </h3>
        ) : null}
      {/* @todo name is not a dependable variable here */}
      {_rawSummary && !["carouselCards", "carouselCardsHero"].includes(layout) && (
        <div className={cn("pt-4", site.isHomepage || site?.doc._type === "offering" ? "md:w-2/3" : null)}>
          <PortableText
            blocks={_rawSummary}
            className={isColumn ? portableTextStyles.checkList : null}
          />
        </div>
      )}
    </div>
  ) : null;
};

export const PillHeader = ({ header, isDarkBg, background, className }) =>
  header ? (
    <div
      className={cn(
        "rounded-full text-black font-medium px-4 pr-5 py-2 inline-block mb-4 whitespace-nowrap",
        background === "mint"
          ? "bg-white text-green-emerald"
          : isDarkBg
            ? "bg-green-emerald text-black"
            : "bg-gray-900 text-white",
        className
      )}
    >
      <div className="flex gap-2 items-center">
        <SparklesIconOnly />
        <h4>{header}</h4>
      </div>
    </div>
  ) : null;
