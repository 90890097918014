import React, { useContext, useEffect, useRef, useState } from "react";
import { cn } from "../../lib/helpers";
import PortableText from "../portableText";
import { getComponentMap } from ".";
import { ImageBuilder } from "../global/image-builder";
import { useInView } from "react-intersection-observer";
import { SiteContext } from "../global/site-context";

import * as styles from "../global/global.module.css";

/**
 * Auto-advance tabbed deck with navigation.
 *
 * @todo merge with carousel-slides.js
 *
 * @todo avoid duplicate code in: carousel-slides.js tabbed.js card-carousel.js carousel-cardswipe.js
 */

const IMAGE_WIDTH = 1024;
export const SLIDE_SPEED = 4000;

export const Tabbed = ({ section }) => {
  // @todo use section.container map to image width via theme screensize
  // widthMap.get(section.container)
  const site = useContext(SiteContext);
  const [active, setActive] = useState(0);
  const available = getComponentMap();
  const [isAutoplayed, setIsAutoplayed] = useState(false);
  const autoPlayRef = useRef();

  const { ref, inView } = useInView({
    initialInView: false,
    onChange: (inView) => {
      if (inView && site.isNotMobile) {
        setIsAutoplayed(true);
      }
    },
  });

  const tabs = section.content.map((c) => {
    switch (c.content?.[0]._type) {
      case "pageComponent":
        const Tag = available.get(c.content?.[0].name).component;
        return {
          header: c.content?.[0].header,
          component: (
            <>
              <PortableText
                blocks={c.content?.[0]._rawSummary}
                className="mb-6 max-w-2/3"
              />
              <Tag section={c.content?.[0]} />
            </>
          ),
        };

      case "pageSectionSimple":
        return {
          header: c.content?.[0].header,
          component: c.content?.[0]._rawContent ? (
            <>
              <PortableText
                blocks={c.content?.[0]._rawContent}
                className="mb-6 md:max-w-2/3"
              />
              {c.content?.[0].image ? (
                c.content?.[0].image.link ? (
                  <a href={c.content?.[0].image.link}>
                    <ImageBuilder
                      image={c.content?.[0].image}
                      width={IMAGE_WIDTH}
                      alt={`Visual to explain ${c.content?.[0].header}`}
                    />
                  </a>
                ) : (
                  <ImageBuilder
                    image={c.content?.[0].image}
                    width={IMAGE_WIDTH}
                    alt={`Visual to explain ${c.content?.[0].header}`}
                  />
                )
              ) : null}
            </>
          ) : null,
        };
      default:
        return {};
    }
  });

  useEffect(() => {
    clearInterval(autoPlayRef.current);
    if (inView && isAutoplayed) {
      autoPlayRef.current = setInterval(() => {
        setActive((oldActive) =>
          oldActive < tabs.length - 1 ? oldActive + 1 : 0
        );
      }, SLIDE_SPEED);
    }

    return () => clearInterval(autoPlayRef.current);
  }, [isAutoplayed, inView]);

  return (
    <>
      <div ref={ref}></div>
      <ul className="flex items-center flex-wrap border-b border-dark-10 mb-12">
        {tabs.map((tab, i) => (
          <li key={i}>
            <a
              role="button"
              className={cn(
                "group cursor-pointer inline-block py-3 md:py-4 pr-3 md:text-xl font-semibold leading-tight border-b-2 transition-nav mr-3 md:mr-0",
                i !== 0 ? "md:pl-3" : null,
                i === active
                  ? "text-dark-90 border-black"
                  : "text-link border-transparent hover:border-link"
              )}
              onClick={(e) => {
                setActive(i);
                setIsAutoplayed(false);
                e.preventDefault();
              }}
              key={i}
            >
              {tab.header || `Tab ${i}`}
            </a>
          </li>
        ))}
        <li className="hidden sm:flex ml-4 pb-1 flex-grow justify-end">
          <a
            onClick={() => {
              setIsAutoplayed(!isAutoplayed);
            }}
            className={cn("group relative", styles.carouselButton)}
            role="button"
            aria-label="autoplay"
          >
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 right-0 bottom-0 left-0 bg-dark-10 rounded-lg"></div>
            <img
              src={`/static/animation/circular-progress.${isAutoplayed ? "gif" : "png"
                }`}
              width={20}
            />
          </a>
        </li>
      </ul>
      <div>{tabs[active].component}</div>
    </>
  );
};
