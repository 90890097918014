import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { cn } from "../../lib/helpers";

export const CheckMark = ({ color, className, size = "lg" }) => (
  <span className={color ? `text-${color}` : null}>
    <FontAwesomeIcon icon={faCheckCircle} className={cn("hidden sm:block", className)} size={size} />
    <FontAwesomeIcon icon={faCheckCircle} className={cn("sm:hidden", className)} size="sm" />
  </span>
);
