import React, { useContext } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { pageDocPath } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { DOCS_MENU } from "../../lib/docs-menu-flat";
import { SiteContext } from "../global/site-context";

const query = graphql`
  query DocsListQuery {
    docs: allSanityDocumentation(
      sort: { fields: [orderRank] }
      filter: { status: { eq: "published" }, isFeatured: { eq: true } }
    ) {
      edges {
        node {
          _id
          _type
          title
          menuTitle
          slug {
            current
          }
          _rawExcerpt
          menu
          menuParent
        }
      }
    }
  }
`;

export const DocsList = ({ section, hideFilters, thin }) => {
  const site = useContext(SiteContext);
  const data = useStaticQuery(query) || {};
  const docs = data && data.docs && mapEdgesToNodes(data.docs);

  return (
    <div className={cn(
      "grid grid-cols-2 gap-3",
      thin ? null : "md:grid-cols-4",
    )}>
      {DOCS_MENU.filter((d) => !d.parent && !d.hideCard).map((m, i) => {
        const children = DOCS_MENU.filter((c) => c.parent === m.id);
        return (
          <div
            className="bg-white border bg-dark-2 rounded-xl p-4 shadow-sm tracking-tight"
            key={i}
          >
            <h2 className="text-lg font-medium mb-4">
              {m.path === site.location.pathname ? (
                <span className="text-primary">
                  {m.title}
                </span>
              ) : (
                <a href={m.path} className="text-primary hover:text-link">
                  {m.title}
                </a>
              )}
            </h2>
            <ul className="leading-tight ml-1 mb-2">
              {docs
                .filter(
                  (d) =>
                    d.menu === m.id || children?.find((c) => d.menu === c.id)
                )
                .slice(0, 5)
                .map((d) => (
                  <li key={d._id} className="mt-4">
                    <a
                      href={pageDocPath(d)}
                      className="group hover:text-link flex align-start inline-block"
                    >
                      <FontAwesomeIcon
                        icon={faBookmark}
                        className="mr-2 mt-0.5 text-link group-hover:text-link max-h-4"
                        size="sm"
                      />
                      {d.menuTitle === "Overview" || !d.menuTitle
                        ? d.title
                        : d.menuTitle}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
