import React from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CarouselCardSwipe } from "../carousel-cardswipe";
import { ImageBuilder } from "../../global/image-builder";
import PortableText from "../../portableText";

const query = graphql`
  query SignalSourcesQuery {
    docs: allSanitySignalSource(
      sort: { fields: [orderRank] }
      filter: { status: { in: ["published"] } }
    ) {
      edges {
        node {
          _type
          _id
          title
          _rawExcerpt
          sourcePeer
          headline
          mainImage {
            ...SanityImage
          }
        }
      }
    }
  }
`;

const PEER_LABEL_MAP = new Map([
  [1, "1st-party"],
  [2, "2nd-party"],
  [3, "3rd-party"],
]);

export const SignalSources = ({ section }) => {
  const data = useStaticQuery(query) || {};
  const docs = data && data.docs && mapEdgesToNodes(data.docs);

  return (
    <CarouselCardSwipe
      section={section}
      docs={docs}
      renderItem={({ doc }) => (
        // select-none
        <div className="w-full rounded-2xl border border-dark-10 relative bg-purple-default mr-6 xl:mr-0">
          <ImageBuilder
            image={doc.mainImage}
            alt={doc.title}
            width={840}
            height={450}
            draggable="false"
          />
          <div className="bg-white p-4 md:p-6 rounded-b-2xl h-40 sm:h-52 md:h-56 lg:60 overflow-hidden">
            <h4 className="font-semibold text-sm text-link mb-2">
              {doc.title}
            </h4>
            <h5 className="font-semibold mb-3">{doc.headline}</h5>
            <PortableText
              blocks={doc._rawExcerpt}
              className="text-sm md:text-base leading-tight text-secondary"
            />
          </div>
          <div className="absolute top-3 right-3">
            {PEER_LABEL_MAP.has(doc.sourcePeer) && (
              <>
                <span className="inline-block py-1 px-2 rounded-md text-xs tracking-wide text-white bg-highlight-default">
                  New
                </span>
                <span className="inline-block py-1 px-2 rounded-md text-xs tracking-wide text-white ml-2 bg-purple-deep">
                  {PEER_LABEL_MAP.get(doc.sourcePeer)}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};
