import React from "react";
import { cn } from "../../lib/helpers";

const COPILOT_ID = "copilot_cm2wdnr0e00020ks65nyz1leq";

export const Chatbot = ({ section }) => {
  //   useEffect(() => {
  //     const chat = document.querySelector("scout-copilot");
  //     chat.embedded = true;
  //   }, []);

  return (
    <div className={cn(
      "flex",
      section?.layout === "centered" ? "justify-center" : "justify-start"
    )}>
      <div className=
        "bg-light-60 border border-dark-5 shadow-lg p-3 rounded-xl flex z-50"
      >
        <scout-copilot
          copilot_id={COPILOT_ID}
          embedded={true}
          height="460px"
          className="mx-auto min-h-64"
        >
          {/* <div slot="fab" className="bg-red p-4 rounded-full">
          <span>Ask AI</span>
        </div> */}
        </scout-copilot>
      </div>
    </div>
  );
};
