import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import { CheckMark } from "./icon/check-mark";
import { SiteContext } from "./global/site-context";

// sorta like markdown, legacy content
const markLink = /(\{([^\}]+)}) *\[([^\]]+)\]/g;
const markToLink = (md) =>
  md
    ? md.replace(
      markLink,
      `<a href="$3" class="text-link hover:text-black"> $2 </a>`
    )
    : md;

export const BlockTable = ({ node }) => {
  const site = useContext(SiteContext);
  const isDoc = site?.doc._type === "documentation";

  const [head, ...rows] = node._type === "table" ? node.rows : node.table.rows;

  const noTopHeader = ["firstColHeaders", "noHeaders"].includes(node.layout);

  return (
    <div
    // className={
    //   rows.length > 12
    //     ? "overflow-y-scroll max-h-144 border-r border-dark-10"
    //     : null
    // }
    >
      <table
        className="w-full text-left mt-4 mb-6 border-collapse rounded-lg text-sm"
        style={{
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
        <thead>
          <tr className="border-b border-dark-5">
            {head.cells.map((cell, i) => (
              <th
                className={cn(
                  "p-3",
                  i > 0 ? "border-l border-dark-10" : "rounded-tl-lg",
                  i === head.cells.length - 1 ? "rounded-tr-lg" : null,
                  (node.layout === "firstColHeaders" && i === 0) || !noTopHeader
                    ? isDoc
                      ? "font-semibold bg-dark-2"
                      : "font-semibold bg-lavender"
                    : "font-normal"
                )}
                key={`cell--${i}`}
              >
                {cell}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr
              key={row._key}
              className={cn(
                "border-b border-dark-5",
                !isDoc ? "odd:bg-dark-2" : null
              )}
            >
              {row.cells.map((cell, i) => {
                return (
                  <td
                    className={cn(
                      "p-3 leading-tight",
                      i > 0 ? "border-l border-dark-10" : null,
                      cell.length < 10 ? "whitespace-nowrap" : null,
                      ["firstColHeaders", "bothHeaders"].includes(
                        node.layout
                      ) && i === 0
                        ? isDoc
                          ? "font-semibold bg-dark-2"
                          : "font-semibold bg-lavender"
                        : null
                    )}
                    key={`${row._key}-${cell}-${i}`}
                  >
                    <CellContent {...{ cell, i }} />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CellContent = ({ cell, i }) =>
  cell === "+" ? (
    <CheckMark size="xl" color={i === 2 ? "purple-darker" : null} />
  ) : cell === "-" ? (
    <svg
      className="text-dark-30"
      width="22"
      height="6"
      viewBox="0 0 22 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="22" height="5" rx="2.5" fill="currentColor" />
    </svg>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: markToLink(cell) }}></div>
  );
