module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || "vt347z5x",
    dataset: "production",
  },
  ga: {
    trackingId: "G-14HMQ5C8C6",
  },
  gtm: {
    trackingId: "GTM-WVJV73N",
  },
  scout: {
    paths: [
      "/demo/schedule/",
      "/demo/schedule-ent/",
      "/help/",
      "/docs/get-started/",
      "/docs/",
      "/movein/",
    ],
  },
  site: {
    websiteParam: "website_attribution",
    joinSlackPath: "/join-slack/",
    signupPath: "/signup/",
    signupPhrase: "Start for free",
    demoPath: "/demo/",
    demoPhrase: "Book a demo",
    host: "https://www.commonroom.io",
  },
};
