import React, { useContext, useEffect } from "react";
import { Slack } from "../../icon/slack";
import { SiteContext } from "../../global/site-context";
import { EmailSignup } from "../../global/email-signup";

export const SlackJoin = ({ section }) => {
  const site = useContext(SiteContext);

  return (
    <div
      className={
        section
          ? "sm:bg-dark-2 rounded-lg sm:p-6 md:p-10 sm:border sm:border-dark-10"
          : null
      }
    >
      <div className={section ? "max-w-md mx-auto text-center" : null}>
        <EmailSignup
          buttonText="Join Slack"
          buttonClasses="bg-brand-slack"
          attribution={{
            website_attribution: "join-slack",
            action: "join-slack",
            label: "email-only",
          }}
          domId="join-slack"
          supressMessage={null}
          placeholder="Email address"
          toggle={() => {
            setTimeout(() => {
              window.location.href = site.slack;
            }, 3000);
          }}
          showChannels={true}
        />
      </div>
    </div>
  );
};

export const SlackJoinRedirect = () => {
  // @todo create CMS doc schema for channels and skip this one-off setting
  const site = useContext(SiteContext);

  useEffect(() => {
    if (site.isBrowser) {
      window.location.replace(site.slack);
    }
  }, []);

  return (
    <div className="mx-auto text-center">
      <a
        href={site.slack}
        onClick={() => {
          site.metrics.logClick(`join-slack-redirect-click`);
        }}
      >
        <div className="flex justify-center items-center transform transition-card scale-100 hover:scale-105">
          <Slack size="96" />
        </div>
      </a>
    </div>
  );
};
