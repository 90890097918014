import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../../lib/helpers";
import { Byline } from "../../card/byline";
import lottie from "lottie-web";
import animationCustomer from "../../../../static/animation/careers-values-customer.json";
import animationExcellence from "../../../../static/animation/careers-values-excellence.json";
import animationSimplify from "../../../../static/animation/careers-values-simplify.json";
import animationTogether from "../../../../static/animation/careers-values-together.json";
import { CardContext } from "../../card/context";
import { Arrow } from "../quote-panels";
import { ImageBuilder } from "../../global/image-builder";
import PortableText from "../../portableText";

import * as styles from "../../global/global.module.css";

const helloPictures = [
  "common-room-epic-catch-gassworks-park.jpg",
  "common-room-video-chat.jpg",
  "common-room-happy-hour.jpg",
  "common-room-stadium.jpg",
  "common-room-happy-hour-2.jpg",
  "common-room-happy-hour-3.jpg",
  "common-room-wall.jpg",
  "common-room-long-table.jpg",
];

export const CareersHello = ({ section }) => {
  // const [hovering, setHovering] = useState(0);
  // const [active, setActive] = useState(0);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setActive(!active);
  //   }, 3000);
  // }, [active]);
  // console.log(active);

  return (
    <div className="grid md:grid-cols-1/3-2/3 gap-8">
      <TextPassage
        header={section.header}
        portableText={section._rawSummary}
        author={{
          name: "Linda Lian",
          title: "The future of GTM",
          url: "/blog/common-room-ai-agent-pipeline-generation/",
          imageSrc: "/static/img/linda-lian.jpg",
        }}
      />
      <div>
        <div className="grid grid-cols-2/3-1/3 gap-1">
          <div
            style={{
              backgroundImage: `url('/static/img/${helloPictures[0]}')`,
            }}
            className="relative bg-cover bg-center rounded-xl h-96"
          >
            <div
              className={cn(
                styles.fadeLoopLong,
                "w-full h-full bg-cover bg-center rounded-xl h-96"
              )}
              style={{
                backgroundImage: `url('/static/img/${helloPictures[6]}')`,
              }}
            ></div>
            <div
              className={cn(
                styles.fadeLoopLong,
                styles.fadeLoopLongAlt,
                "absolute top-0 w-full h-full bg-cover bg-center rounded-xl h-96"
              )}
              style={{
                backgroundImage: `url('/static/img/${helloPictures[7]}')`,
              }}
            ></div>
          </div>
          <div
            style={{
              backgroundImage: `url('/static/img/${helloPictures[5]}')`,
            }}
            className="bg-cover bg-left rounded-xl h-96"
          >
            <div
              className={cn(
                styles.fadeLoop,
                styles.fadeLoopAlt,
                "w-full h-full bg-cover bg-start rounded-xl h-96"
              )}
              style={{
                backgroundImage: `url('/static/img/${helloPictures[1]}')`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const companyValues = [
  {
    header: "Be Customer-centric",
    text: "We work backwards from the needs of our customers. The crisp articulation of customer value guides our decisions.",
    media: animationCustomer,
  },
  {
    header: "Strive for Simplicity",
    text: "We choose simplicity over complexity whenever possible. We seek to identify and understand the essential quality of what we are building.",
    media: animationSimplify,
  },
  {
    header: "Make it Happen",
    text: "We are quick to take the first step, and prioritize decisiveness over fear of making a mistake. We don't confuse motion for movement and we measure ourselves on impact over actions.",
    media: animationExcellence,
  },
  {
    header: "We're In this Together",
    text: "We measure personal success by the success of our customers and teammates. Relationships matter, and the strongest ones are built on trust, enablement, and transparency.",
    media: animationTogether,
  },
];

export const CareersCarousel = ({ section }) => {
  const [active, setActive] = useState(0);
  const [clickToggle, setToggle] = useState(false);
  const media = useRef();

  const handlePicked = (i) => {
    if (i !== active) {
      setActive(i);
    } else {
      setToggle(!clickToggle);
    }
  };

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: media.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: companyValues[active].media,
      rendererSettings: { className: "rounded-2xl" },
    });
    anim.onComplete = () => {
      setActive(active >= companyValues.length - 1 ? 0 : active + 1);
    };

    return () => anim.destroy();
  }, [active, clickToggle]);

  return (
    <div className="grid md:grid-cols-2 gap-12">
      <div className="flex flex-col justify-between">
        <TextPassage
          header={section.header}
          portableText={section._rawSummary}
          className="mb-8"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-10 gapy-6">
          {companyValues.map((slide, i) => (
            <div
              className={i === active ? "" : "text-dark-50 hover:text-black"}
              onFocus={() => handlePicked(i)}
              onClick={() => handlePicked(i)}
              role="button"
              key={i}
            >
              <TextPassage header={slide.header} blocks={[slide.text]} />
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-lavender rounded-2xl w-full flex justify-center items-center"
        ref={media}
      ></div>
    </div>
  );
};

const companyBenefits = [
  {
    text: "Full coverage health, dental, and vision insurance",
    hoverText: "Forget to floss again? We won't tell.",
    color: "#CBED83",
    emoji: "tooth",
    image: "medical.jpg",
  },
  {
    text: "Unlimited paid time off and paid holidays",
    hoverText: "We hear Idaho's nice, you could go there.",
    // green-600
    color: "#83EDBF",
    emoji: "palm",
    image: "outdoors.jpg",
  },
  {
    text: "401(k) self contribution program",
    hoverText: "Adulting is hard, but this should make it a little easier.",
    color: "#83E0ED",
    emoji: "cool",
    image: "money.jpg",
  },
  {
    text: "Stipend to make your workspace shine",
    hoverText: "Get the mechanical keyboard of your dreams.",
    color: "#83AEED",
    emoji: "chair",
    image: "office.jpg",
  },
];

export const CareersBenefits = ({ section }) => (
  <div className="grid grid-cols-2 gap-1 md:grid-cols-4 text-xl text-center h-full">
    {companyBenefits.map((card, i) => (
      <div
        className="relative text-center rounded-2xl j-72 sm:h-96 xl:h-144 p-4 md:p-8 grid grid-rows-2 place-items-center bg-cover bg-center group"
        style={{ backgroundImage: `url('/static/img/${card.image}')` }}
        key={i}
      >
        <div
          className="absolute w-full h-full rounded-2xl opacity-100 transition-opacity duration-300 group-hover:opacity-0"
          style={{ backgroundColor: card.color }}
        ></div>
        <div className="absolute top-0 w-3/4 flex items-start pt-12 sm:pt-16 text-white opacity-0 group-hover:opacity-100">
          {card.hoverText}
        </div>
        <div className="relative h-full flex items-start pt-8 opacity-100 group-hover:opacity-0">
          {card.text}
        </div>
        <div className="relative transition-opacity duration-300 opacity-100 group-hover:opacity-0">
          <ImageBuilder src={`/static/img/${card.emoji}-emoji.svg`} />
        </div>
      </div>
    ))}
  </div>
);

const scaleClass = "transform transition-transform scale-100 hover:scale-125";

export const CareersQuoteCluster = ({ section }) => (
  <>
    <TextPassage
      header={section.header}
      portableText={section._rawSummary}
    />
    <QuoteCluster
      quotes={[
        {
          text: "I enjoyed the interview process, and it's clear you put a lot of thought into designing it. In particular, the tech questions were much more relevant and practical than most tech interviews I've seen.",
          image: "emoji-face-right.svg",
          color: "lavender",
        },
        {
          text: "The founders complement each other well and the team is pretty experienced overall. It's rewarding building something brand new with a mature team.",
          image: "emoji-avocado.svg",
          color: "mint",
        },
        {
          text: "I thoroughly enjoyed discussing the values at Common Room. I also appreciated your infectious energy and enthusiasm - it's the kind of energy that I used to be surrounded with [and] was a tangible reminder of what I'm looking for.",
          image: "emoji-nessy.svg",
          color: "green-900",
        },
      ]}
    />
  </>
);

const QuoteCluster = ({ quotes }) => (
  <>
    <div className="hidden sm:block">
      <div
        className="grid gap-1 max-w-3xl mx-auto text-2xl mt-12 mb-1"
        style={{
          gridTemplateAreas: `"a b" "c b"`,
          gridTemplateColumns: "50% auto",
        }}
      >
        <div style={{ gridArea: "a" }} className="flex justify-end">
          <ImageBuilder
            src="/static/img/emoji-face-right.svg"
            className={cn("-mt-6 mr-2 h-12", scaleClass)}
          />
          <Arrow direction="up" color="lavender" className="-mr-6" />
        </div>
        <div
          style={{ gridArea: "b" }}
          className="rounded-2xl rounded-tl-none p-6 bg-lavender"
        >
          {quotes[0].text}
        </div>
        <div
          style={{ gridArea: "c" }}
          className="rounded-2xl rounded-bl-none p-6 bg-mint"
        >
          {quotes[1].text}
        </div>
      </div>

      <div
        className="grid max-w-3xl mx-auto text-2xl"
        style={{
          gridTemplateAreas: `"e f g" "h f g" "h i g"`,
          gridTemplateColumns: "10% 80% 10%",
        }}
      >
        <div style={{ gridArea: "e" }}>
          <Arrow direction="right" color="mint" className="-mt-6" />
        </div>
        <div style={{ gridArea: "f" }} className="rounded-2xl p-6 bg-green-900">
          "{quotes[2].text}"
        </div>
        <div style={{ gridArea: "g" }}>&nbsp;</div>
        <div style={{ gridArea: "h" }}>
          <ImageBuilder
            src="/static/img/emoji-avocado.svg"
            className={cn("h-16 -ml-6", scaleClass)}
          />
        </div>
        <div style={{ gridArea: "i" }} className="flex flex-col items-center">
          <Arrow direction="up" color="green-900" />
          <ImageBuilder
            src="/static/img/emoji-nessy.svg"
            className={cn("h-12 mt-2", scaleClass)}
          />
        </div>
      </div>
    </div>
    <div className="block sm:hidden">
      {quotes.map((q, i) => (
        <div className="text-2xl mb-4" key={i}>
          <div className={cn(`bg-${q.color}`, "rounded-2xl p-6")}>{q.text}</div>
          <div className="flex flex-col items-center">
            <Arrow direction="up" color={q.color} />
            <ImageBuilder
              src={`/static/img/${q.image}`}
              className="h-12 mt-2"
            />
          </div>
        </div>
      ))}
    </div>
  </>
);

export const TextPassage = ({ header, blocks, author, className, portableText }) => (
  <div className={cn("max-w-md", className)}>
    <h4 className="font-semibold text-sm mb-2">{header}</h4>
    {portableText ? (
      <PortableText blocks={portableText} />
    ) : blocks ? (
      <>
        {blocks.map((block, i) => (
          <p
            key={i}
            className="mb-6"
            dangerouslySetInnerHTML={{ __html: block }}
          ></p>
        ))}
      </>
    ) : null}
    {author && (
      <div className="mt-8">
        <CardContext.Provider value={{ authors: [{ author: author }] }}>
          <Byline />
        </CardContext.Provider>
      </div>
    )}
  </div>
);
