import React, { useEffect, useState } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { Card } from "../card/card";
import { CardContext } from "../card/context";
import { isPast, isFuture } from "date-fns";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import * as styles from "./calendar.module.css";

const query = graphql`
  query InviteListQuery {
    docs: allSanityInvite(
      sort: { fields: [startDate], order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeInvite
        }
      }
    }
  }
`;

const dateMatch = (date, dateToMatch) =>
  new Date(date).getDate() === new Date(dateToMatch).getDate() &&
  new Date(date).getMonth() === new Date(dateToMatch).getMonth() &&
  new Date(date).getFullYear() === new Date(dateToMatch).getFullYear();

const dateBasedBg = (invite) =>
  isFuture(new Date(invite.startDate))
    ? invite.url || !invite.inviteOnly
      ? "bg-purple-darker"
      : "bg-mint"
    : "bg-dark-20";

export const InviteList = ({ section }) => {
  const [future, setFuture] = useState([]);
  const [past, setPast] = useState([]);
  const [value, _setValue] = useState([]);
  const { docs } = useStaticQuery(query);
  const docList = docs && mapEdgesToNodes(docs);
  const now = new Date();

  useEffect(() => {
    setPast(docList.filter((e) => isPast(new Date(e.startDate))));
    setFuture(docList.filter((e) => isFuture(new Date(e.startDate))).reverse());
    //setValue(future[future.length - 1]);
  }, [docs]);

  return (
    <>
      <Calendar
        value={value}
        // onChange={(_val, e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        // }}
        minDate={new Date(new Date(now).setMonth(now.getMonth() - 4))}
        maxDate={new Date(new Date(now).setMonth(now.getMonth() + 4))}
        minDetail="month"
        tileContent={({ date, view }) => {
          const match = docList.find((invite) =>
            dateMatch(date, invite.startDate)
          );
          return view === "month" ? (
            match ? (
              <>
                <div
                  className={cn(
                    // "h-4 w-4 rounded-full absolute top-1/2 left-1/2 mt-2 -ml-2",
                    // "md:top-px md:right-px md:bottom-px md:left-px md:opacity-50 md:rounded-lg md:m-0 md:h-auto md:w-auto",
                    "absolute top-px right-px bottom-px left-px opacity-40 rounded-lg",
                    dateBasedBg(match)
                  )}
                ></div>
                <p className="mt-1 relative hidden sm:block text-xs px-1">
                  {match.location.split(",")[0]}
                </p>
                <p className="sm:hidden text-xs mt-2">click</p>
                <a
                  href={`#${match._id}`}
                  className="absolute top-px right-px bottom-px left-px bg-black opacity-0 pointer-events-auto hover:opacity-5 blend-mode-overlay rounded-lg"
                ></a>
              </>
            ) : (
              dateMatch(date, now) && (
                <div className="absolute top-px right-px bottom-px left-px bg-light-40 rounded-lg"></div>
              )
            )
          ) : null;
        }}
        className={cn(
          "border-none w-full rounded-xl overflow-hidden mb-8 bg-dark-5",
          styles.reactCalendar
        )}
        tileClassName={cn(
          "relative h-16 flex flex-col items-center justify-start bg-white pointer-events-none",
          styles.reactCalendarTile
        )}
      />
      {future.length > 0 ? (
        <ul className="grid gap-4 grid-cols-1">
          {future.map((invite) => (
            <li key={invite.id} className="mb-4 relative">
              <a id={invite._id} className="absolute -top-20"></a>
              <CardContext.Provider
                value={{
                  ...invite,
                  showSummary: true,
                  skipLink: invite.inviteOnly,
                  cta: invite.inviteOnly
                    ? "Invite only"
                    : invite.url
                    ? "Learn more and RSVP"
                    : "Request invite",
                  url:
                    !invite.url && !invite.inviteOnly
                      ? "/contact/"
                      : invite.url,
                  targetBlank: !!invite.url,
                  fullSummary: true,
                }}
              >
                <Card />
              </CardContext.Provider>
            </li>
          ))}
        </ul>
      ) : (
        <p className="md:ml-3 font-semibold text-auxiliary text-center">
          Loading...
        </p>
      )}
      {past.length > 0 && (
        <>
          <div className="my-8 pt-4 border-t border-dark-30">Recent Events</div>

          <ul className="grid gap-4 grid-cols-1 opacity-60 hover:opacity-100">
            {past.slice(0, 2).map((invite) => (
              <li key={invite.id} className="mb-4">
                <CardContext.Provider
                  value={{
                    ...invite,
                    skipLink: !invite.url,
                    cta: invite.url ? "View details" : "Invite only",
                    showSummary: true,
                    fullSummary: true,
                    targetBlank: !!invite.url,
                  }}
                >
                  <Card />
                </CardContext.Provider>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};
