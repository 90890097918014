module.exports = {
  purge: {
    content: [
      "./src/**/*.html",
      "./src/**/*.js",
      "./static/**/*.html",
      "./static/**/*.js",
    ],
    options: {
      // protect dynamic colors
      safelist: [
        "bg-black",
        "bg-white",
        "bg-mint",
        "bg-yellow-200",
        "text-5xl",
        "text-6xl",
        "text-7xl",
        "text-8xl",
        "font-thin",
        "font-extralight",
        "font-light",
        "font-base",
        "font-extrabold",
        "md:grid-cols-2",
        "md:grid-cols-3",
        "md:grid-cols-4",
        "md:grid-cols-5",
        "text-purple-400",
        "text-blue-400",
        "text-red",
        "text-magenta",
        "text-orange-400",
        "bg-purple-400",
        "bg-blue-400",
        "bg-red",
        "bg-magenta",
        "bg-orange-400",
        "bg-orange-800",
        "bg-light-90",
        "bg-dark-80",
        "bg-dark-90",
        "text-dark-80",
        "hover:bg-blue-100",
        "hover:bg-purple-800",
        "hover:bg-brand-dbt",
        "hover:bg-brand-atlassian",
        "hover:bg-brand-mmhmm",
        "hover:bg-brand-asana",
        "hover:bg-brand-confluent",
        "hover:bg-brand-contra",
        "hover:bg-brand-roam",
        "hover:bg-brand-imply",
        "hover:text-brand-slack2",
        "hover:text-brand-linkedin",
        "hover:text-brand-youtube",
        "hover:text-brand-twitter",
        "hover:text-brand-spotify",
        "hover:text-brand-applepodcasts",
        "text-brand-youtube",
        "text-brand-spotify",
        "text-brand-applepodcasts",
        "border-blue-100",
        "border-green-400",
        "border-purple-400",
        "bg-green-400",
        "text-blue-100",
        "border-orange-800",
        "text-orange-800",
        "border-blue-400",
        "text-blue-400",
        "text-green-400",
        "bg-lavender",
        "bg-lavenderlight",
        "bg-green-900",
        "border-lavender",
        "border-mint",
        "border-green-900",
        "border-teal",
        "bg-teal",
        "bg-lime",
        "bg-robin",
        "bg-lemon",
        "bg-peach",
        "text-gray-400",
        "text-peach",
        "text-lemon",
        "text-lime",
        "text-robin",
        "text-melon",
        "text-blue-600",
        "text-green-200",
        "text-teal",
        "ml-1.5",
        "mr-1.5",
        "bg-limeDark",
        "text-limeDark",
        "border-limeDark",
        "text-peachDark",
        "text-mintDark",
        "bg-melonLight",
        "border-highlight-default",
        "text-highlight-default",
        "text-blue-800",
        "text-green-100",
        "border-dark-50",
        "bg-dark-50",
        "text-dark-50",
        "focus:animate-boop",
        "bg-purple-darker",
        "bg-secondary",
      ],
    },
  },
  darkMode: false,
  theme: {
    container: {
      center: true,
    },
    fontFamily: {
      sans: ["Suisse Intl", "ui-sans-serif", "Arial", "sans-serif"],
      black: ["Suisse Intl Black", "ui-sans-serif", "Arial", "sans-serif"],
      light: ["Suisse Intl Light", "ui-sans-serif", "Arial", "sans-serif"],
      extralight: ["Suisse Intl Light", "ui-sans-serif", "Arial", "sans-serif"],
      thin: ["Suisse Intl Light", "ui-sans-serif", "Arial", "sans-serif"],
      mono: [
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        "Courier New",
        "monospace",
      ],
    },
    fontSize: {
      micro: ["0.625rem", { lineHeight: "1" }], // 10/16
      xs: ["0.75rem", { lineHeight: "1rem" }], // 12/16
      sm: ["0.875rem", { lineHeight: "1rem" }], // 14/16
      base: ["1rem", { lineHeight: "1.375rem" }], // 16/22
      lg: ["1.125rem", { lineHeight: "1.5rem" }], // 18/24
      xl: ["1.25rem", { lineHeight: "1.5rem" }], // 20/24
      "2xl": ["1.625rem", { lineHeight: "1.875rem" }], // 26/30
      "3xl": ["1.875rem", { lineHeight: "2.125rem" }], // 30/34
      "xl-homepage": ["2rem", { lineHeight: "2.25rem" }],
      "4xl": ["2rem", { lineHeight: "2.5rem" }], // 32/40
      // @todo swap 5xl + 6xl in an isolated PR
      "5xl": ["2.25rem", { lineHeight: "2.75rem" }],
      "6xl": ["2.75rem", { lineHeight: "3rem" }], // 44/48
      "7xl": ["3.125rem", { lineHeight: "3.25rem" }], // 46/52
      "8xl": ["4rem", { lineHeight: "4rem" }], // 60/64
      "9xl": ["4.5rem", { lineHeight: "4.5rem" }], // 72/72
    },
    colors: {
      transparent: "transparent",
      current: "currentColor",
      black: "#000000",
      white: "#FFFFFF",
      primary: "#2D2D2D",
      secondary: "#6F727C",
      auxiliary: "#ABAEB8",
      navText: "#53555B",
      magenta: "#EE0FD8",
      peach: "#FFF1CC",
      peachLight: "rgba(255, 196, 108, .3)",
      peachDark: "#A27500",
      limeLight: "rgba(191, 255, 168, .5)",
      lime: "#ECFFB6",
      limeDark: "#19AA08",
      robin: "#C3F0FF",
      lemon: "#F8F7B3",
      lemonDark: "#d0ce61",
      melon: "#f9cecf",
      melonLight: "#FADADC",
      teal: "#66BBA9",
      mint: "#BDF9DF",
      mintDark: "#006338",
      red: "#EC4B29",
      link: "#1BC88C",
      // replace usage with nested
      lavender: "#EBE2F9",
      lavenderlight: "rgba(235, 226, 249, .5)",
      purple: {
        ultralight: "#F8F5FB",
        // @todo combine following two near duplicates
        light: "rgba(235, 226, 249, .5)",
        default: "#EBE2F9",
        darker: "#D7C6F3",
        lavender: "#B099D6",
        deep: "#6D5692",
        deeper: "#7353A7",
        300: "#8F40F2", // text gradient
        400: "#8062B0", // pill
        700: "#592366", // badge
        brand: "#723EC7",
      },
      highlight: {
        default: "#3F64EF",
        hover: "#3A59CB",
        press: "#334EB3",
        button: "#ECEFFD",
      },
      light: {
        2: "rgba(256, 256, 256, .02)",
        5: "rgba(256, 256, 256, .05)",
        10: "rgba(256, 256, 256, .1)",
        20: "rgba(256, 256, 256, .2)",
        30: "rgba(256, 256, 256, .3)",
        40: "rgba(256, 256, 256, .4)",
        50: "rgba(256, 256, 256, .5)",
        60: "rgba(256, 256, 256, .6)",
        70: "rgba(256, 256, 256, .7)",
        80: "rgba(256, 256, 256, .8)",
        90: "rgba(256, 256, 256, .9)",
      },
      dark: {
        1: "rgba(0, 0, 0, 0.01)",
        2: "rgba(0, 0, 0, 0.02)",
        5: "rgba(0, 0, 0, 0.05)",
        10: "rgba(0, 0, 0, 0.1)",
        20: "rgba(0, 0, 0, 0.2)",
        30: "rgba(0, 0, 0, 0.3)",
        40: "rgba(0, 0, 0, 0.4)",
        50: "rgba(0, 0, 0, 0.5)",
        60: "rgba(0, 0, 0, 0.5)",
        80: "rgba(0, 0, 0, 0.8)",
        85: "rgba(0, 0, 0, 0.85)",
        90: "rgba(0, 0, 0, 0.9)",
      },
      gray: {
        200: "#F6F6F6",
        400: "#BEBDBD",
        600: "#828282",
        800: "#414141",
        900: "#222222",
        1000: "#1A1A1A",
      },
      orange: {
        200: "#DE633A",
        400: "#EE742F",
        500: "#D26A5E",
        800: "#F37A09",
        900: "#D18614", // text gradient
      },
      blue: {
        100: "#5DD2FA",
        400: "#0F25EE",
        600: "#44A8C9",
        800: "rgba(2, 132, 199, 0.75)",
      },
      yellow: {
        200: "#FFFD54",
      },
      green: {
        100: "rgba(22, 163, 74, 0.75)",
        200: "#779D0A",
        300: "#49A5B3",
        400: "#5DBF8B",
        500: "#2AF409",
        600: "#83EDBF",
        800: "#B6F309",
        900: "#DEFFD9",
        emerald: "#1BC88C",
        ultralight: "#ECF4F0",
        darkPageBg: "#63B99E",
        chatbg: "#d1f3dd",
      },
      brand: {
        pulumi: "#8a3391",
        shortcut: "#351D48",
        dbt: "#0F6064",
        atlassian: "#053AC1",
        mmhmm: "#320EEF",
        asana: "#EA5257",
        contra: "#f2c94c",
        confluent: "#050224",
        imply: "#1B86D8",
        roam: "#137CBD",
        twitter: "#1D9BF0",
        linkedin: "#2867B2",
        slack: "#441949",
        slack2: "#F9B60C",
        youtube: "#FF0000",
        spotify: "#1ED760",
        applepodcasts: "#D56DFB",
        pocketcasts: "#EE252B",
        amazon: "#FF9900",
        reddit: "#FF4500",
        facebook: "#0165E1",
      },
      spotlight: {
        salmon: "rgba(255, 143, 107)",
        robin: "rgba(107, 255, 228)",
      },
    },
    screens: {
      xs: "0px",
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1200px",
      max: "1400px",
      edge: "2000px",
      boundary: "4000px",
      nohover: { raw: "(hover: none)" },
      hashover: { raw: "(hover: hover)" },
    },
    borderRadius: {
      none: "0",
      sm: "0.125rem",
      DEFAULT: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      full: "9999px",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
    },
    extend: {
      gridTemplateColumns: {
        "2/3-1/3": "auto 33%",
        "1/3-2/3": "33% auto",
        "1/6-2/6-3/6": "17% 34% auto",
        "2/5-3/5": "40% auto",
        "3/5-2/5": "auto 40%",
        "3/4-1/4": "75% auto",
        "1/4-3/4": "auto 75%",
        "1/4-1/2-1/4": "25% 50% auto",
        "1/2-1/10-2/5": "50% auto 40%",
        "menu-content-toc": "17.5rem auto 13.75rem",
        "menu-content-toc-full": "17.5rem auto",
      },
      gridTemplateRows: {
        "3/5-2/5": "auto 40%",
      },
      minHeight: {
        7: "1.75rem",
        12: "3rem",
        24: "6rem",
        32: "8rem",
        48: "12rem",
        64: "16rem",
        80: "20rem",
      },
      minWidth: {
        bookDemo: "6.87rem",
        64: "16rem",
        88: "22rem",
      },
      layout: {
        headerHeight: "80px",
      },
      outline: {
        transparent: "1px solid transparent",
        lightBorder: "3px solid rgba(255, 255, 255, 0.4)",
      },
      margin: {
        "1/2": "50%",
        "100vh": "100vh",
        144: "36rem",
      },
      padding: {
        "16/9": "56.25%",
        144: "36rem",
      },
      inset: {
        "-1/10": "-10%",
        "-2p": "-2%",
        "2p": "2%",
        "5p": "5%",
        "1/6": "15%",
        "1/10": "10%",
        "12p": "12%",
        "38p": "38%",
        "4/10": "40%",
        "52p": "52%",
        "57p": "57%",
        "64p": "64%",
        "67p": "67%",
        "69p": "69%",
        "-30r": "-30rem",
      },
      boxShadow: {
        sticky: "0px 4px 20px rgb(0,0,0,0.2)",
        card: "0 2px 5px 0 rgb(93 210 250 / 1)",
        glow: "0 0 120px rgba(189, 249, 223, .4)",
      },
      maxHeight: {
        "70vh": "70vh",
        4: "1rem",
        8: "2rem",
        12: "3rem",
        16: "4rem",
        20: "5rem",
        24: "6rem",
        144: "36rem",
      },
      maxWidth: {
        image: "580px",
        "9/10": "90%",
        "4/5": "80%",
        "3/4": "75%",
        "2/3": "66%",
        "1/2": "50%",
        "1/3": "33%",
        "1/4": "25%",
        120: "30rem",
      },
      height: {
        "qtr-screen": "25vh",
        "half-screen": "50vh",
        "150vh": "150vh",
        "200vh": "200vh",
        "250vh": "250vh",
        "300vh": "300vh",
        144: "36rem",
      },
      top: {
        "80vh": "80vh",
        "100vh": "100vh",
      },
      left: {
        "50vw": "50vw",
        "35vw": "35vw",
      },
      width: {
        7.5: "1.875rem",
        18: "4.5rem",
        60: "15rem",
        88: "22rem",
        97: "26.88rem",
        "1/9": "11.111111%",
        "2/9": "22.222222%",
        "3/9": "33.333333%",
        "4/9": "44.444444%",
        "5/9": "55.555555%",
        "6/9": "66.666666%",
        "7/9": "77.777777%",
        "8/9": "88.888888%",
        144: "36rem",
        160: "40rem",
      },
      opacity: {
        25: "0.25",
        30: "0.3",
        40: "0.4",
        50: "0.5",
        60: "0.6",
        90: "0.9",
      },
      scale: {
        95: "0.95",
        100: "1",
        101: "1.01",
        102: "1.02",
        105: "1.05",
        110: "1.1",
        125: "1.25",
      },
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
      },
      transitionProperty: {
        height: "height",
        spacing: "margin, padding",
        header: "padding, color, background-color",
        nav: "border, color, border-color, border-opacity, background-color",
        border: "border",
        size: "transform",
        card: "transform, background-color, background-image, box-shadow",
        link: "underline, color, opacity",
        opacity: "opacity",
      },
      zIndex: {
        100: "100",
        90: "90",
        80: "80",
        70: "70",
        60: "60",
      },
      borderWidth: {
        DEFAULT: "1px",
        0: "0",
        2: "2px",
        4: "4px",
        6: "6px",
        8: "8px",
        10: "10px",
      },
      aspectRatio: {
        square: "1 / 1",
      },
      keyframes: {
        boop: {
          "0%": { transform: "scale(1.02)" },
          "50%": { transform: "scale(1.07)" },
          "100%": { transform: "scale(1)" },
        },
        sparkle: {
          "0%": { color: "black", transform: "scale(1)" },
          "12.5%": { color: "#3FBE87", transform: "scale(1.05)" },
          "50%": { color: "plack", transform: "scale(1)" },
          "87.5%": { color: "#3FBE87", transform: "scale(0.95)" },
          "100%": { color: "black", transform: "scale(1)" },
        },
        delayRemove: {
          "0%": { opacity: "1" },
          "90%": { opacity: "1" },
          "100%": { opacity: "0" },
        },
      },
      animation: {
        "spin-slow": "spin 3.5s linear infinite",
        boop: "boop 0.15s linear",
        sparkle: "sparkle 1.5s linear infinite",
        delayRemove: "delayRemove 2s linear forwards",
      },
    },
  },
  variants: {
    extend: {
      fontWeight: ["responsive", "hover", "focus"],
      animation: ["hover", "focus"],
      opacity: ["hover"],
      zIndex: ["hover"],
      fill: ["hover"],
      borderWidth: ["first", "last", "odd", "even"],
      borderColor: ["hover", "focus"],
      margin: ["first", "last"],
      gradientColorStops: ["odd", "even"],
      backgroundColor: ["odd", "even"],
      scale: ["hover", "active", "group-hover"],
      display: ["first", "last"],
    },
  },
  plugins: [],
};
