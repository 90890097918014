import React, { useCallback, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes, truncateText } from "../../lib/helpers";
import { ImageBuilder } from "../global/image-builder";
import { pageDocPath } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import * as styles from "../global/global.module.css";

const query = graphql`
  query AccountListQuery {
    allAccounts: allNotion {
      edges {
        node {
          id
          title
          properties {
            Product_Overview___summary {
              value
            }
            Company_website {
              value
            }
            Industry {
              value
            }
          }
        }
      }
    }
  }
`;

export const AccountList = ({ section }) => {
  const data = useStaticQuery(query);
  // @todo ensure source data has no duplicates
  const accounts = [
    ...new Map(
      [].concat(
        mapEdgesToNodes(data.allAccounts)
      ).map((p) => [p.title, p])
    ).values(),
  ];
  const [searchResults, setSearchResults] = useState(accounts);

  return (
    <>
      <div className="flex items-center justify-center my-12 w-full">
        <SearchAccounts setSearchResults={setSearchResults} allNodes={accounts.sort(
          (a, b) => a.title.localeCompare(b.title),
        )} />
      </div>
      <div className={styles.fadeIn}>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
          {searchResults.map((n, i) => <AccountCard key={i} account={n} />)}
        </div>
      </div>
    </>
  );
};

const SearchAccounts = ({ setSearchResults, allNodes }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  const handleSearch = useCallback(
    debounce((term) => {
      if (term.trim() === '') {
        setSearchResults(allNodes)
      } else {
        const results = allNodes.filter((item) =>
          item.title.toLowerCase().includes(term.toLowerCase()),
        )
        setSearchResults(results)
      }
    }, 300),
    [],
  )

  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm, handleSearch])

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="relative flex items-center max-w-120 mx-auto">
        <input
          type="text"
          autoFocus={true}
          value={searchTerm}
          onChange={handleInputChange}
          className="w-full rounded-full bg-white px-5 py-2 pl-12 text-base duration-200 focus:outline-none"
          placeholder="Search accounts"
        />
        <div className="absolute top-0 left-4 flex items-center h-full text-auxiliary">
          <FontAwesomeIcon icon={faSearch} size="lg" />
        </div>
      </div>
    </form>
  )
};

export const AccountCard = ({ account }) => (
  <a
    key={account.id}
    href={pageDocPath({ ...account, _type: "account" })}
    className="block bg-white rounded-lg p-4 border transition-card duration-200 group hover:shadow-lg"
  >
    <div className="flex items-center gap-3 mb-3"
      style={{ minHeight: 42 }}
    >
      <ImageBuilder
        src={`https://logo.clearbit.com/${account?.properties?.Company_website?.value}`}
        alt={account.title}
        width={42}
        className="bg-white rounded-lg"
      />
      <p className="font-semibold text-lg">{account.title}</p>
    </div>
    <p className="text-link mb-2 text-xs">
      {account.properties?.Industry.value}
    </p>
    <p className="text-secondary text-sm leading-snug">{truncateText({
      str: account.properties?.Product_Overview___summary.value || "No summary",
      max: 105
    })}</p>

  </a>
);