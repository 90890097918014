import React, { useContext } from "react";
import { ArrowLeft } from "./icon/arrow-left";
import { SiteContext } from "./global/site-context";
import { cn } from "../lib/helpers";
import { Button } from "./button";

export const BackToZone = ({ to, text }) => {
  const site = useContext(SiteContext);
  const hasBanner = site.banners.length > 0;

  return (
    <div className={cn("pb-6", hasBanner ? "py-6" : null)}>
      <Button
        text={text}
        href={to}
        icon={<ArrowLeft size="12" />}
        bgColor="bg-dark-5"
        className="text-secondary hover:text-primary"
      />
    </div>
  );
};
