import React, { useContext, useEffect, useState } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, Link, useStaticQuery } from "gatsby";
import { matchCategories } from "./resources-list";
import { SectionContext } from "./context";
import { Subscribe } from "./subscribe";
import { ImageBuilder } from "../global/image-builder";
import Masonry from "react-masonry-css";
import { CardContext } from "../card/context";
import { Card } from "../card/card";
import { SiteContext } from "../global/site-context";
import { Logo } from "../icon/logo";

import * as styles from "../global/global.module.css";

const MENU = [
  {
    label: "All",
    slug: "",
  },
  {
    label: "Sales-led",
    slug: "sales",
  },
  {
    label: "Product-led",
    slug: "product-led-growth",
  },
  {
    label: "Open-source",
    slug: "commercial-open-source-software",
  },
  {
    label: "Community-led",
    slug: "community-teams",
  },
  {
    label: "Dark-funnel",
    slug: "dark-funnel",
  },
];

export const SIGNAL_CTA = "Read the guide";

const query = graphql`
  query SignalsListQuery {
    docs: allSanitySignal(
      sort: { fields: [status, title], order: [DESC, ASC] }
      filter: { status: { in: ["published", "hidden"] } }
    ) {
      edges {
        node {
          ...cardNodeSignal
        }
      }
    }
  }
`;

export const SignalsList = ({ section, hideFilters }) => {
  const site = useContext(SiteContext);
  const data = useStaticQuery(query) || {};
  const docs = data && data.docs && mapEdgesToNodes(data.docs);
  const [nodes, setNodes] = useState(docs);
  const miniBlock = section.cards && section.cards < 5;

  return (
    <>
      {/* <Search /> */}
      {!hideFilters && (
        <Filters nodes={docs} setNodes={setNodes} collapse={miniBlock} />
      )}
      <Masonry
        breakpointCols={{
          default: 3,
          1020: 2,
          600: 1,
        }}
        className={styles.masonryGrid}
        columnClassName={styles.masonryGridColumn}
      >
        {nodes.map((doc, i) => (
          <React.Fragment key={i}>
            <CardContext.Provider
              value={{
                ...doc,
                hideImage: true,
                showSummary: true,
                showByline: false,
                hideLabels: true,
                hideType: true,
                hideDate: true,
                panels: true,
                skipLink: doc.status !== "published",
                cta: doc.status === "published" ? SIGNAL_CTA : null,
                isMasonry: true,
                showIntentTag: true,
              }}
            >
              <Card
                className={cn(
                  "border border-dark-10 transition-card duration-200",
                  doc.status === "published"
                    ? "hover:shadow-lg hover:border-dark-10"
                    : "bg-green-ultralight text-secondary"
                )}
                unlinkedOverlay={
                  doc.status !== "published" ? (
                    <SubscribeOverlay
                      message={site.token("signalsSubscribeCard")}
                    />
                  ) : null
                }
              />
            </CardContext.Provider>
            {i === nodes.filter((n) => n.status === "published").length - 1 ? (
              <SubscribeCard header={site.token("signalsSubscribeWidget")} />
            ) : null}
          </React.Fragment>
        ))}
      </Masonry>
    </>
  );
};

const SubscribeCard = ({ header }) => (
  <div
    className="bg-link p-4 md:p-6 mb-4 rounded-xl flex items-center"
    id="subscribe-card"
  >
    <div>
      <Logo iconOnly={true} className="text-white w-16" />
      <p className="text-white text-xl md:text-2xl font-semibold my-12">
        {header}
      </p>
      <Subscribe
        section={{
          buttonText: "Subscribe",
          buttonClasses: "w-auto relative",
          attribution: {
            website_attribution: "signals",
          },
          formDomId: "email-signup-signals",
        }}
        buttonIsSmall={true}
      />
    </div>
  </div>
);

const SubscribeOverlay = ({ message }) => (
  <a
    href="#subscribe-card"
    className="block w-full h-full bg-purple-default p-4 md:p-6 "
  >
    <Logo iconOnly={true} className="text-link w-16" />
    <p className="text-link text-xl font-semibold mt-10">{message}</p>
  </a>
);

const Filters = ({ nodes, setNodes, collapse }) => {
  const [filters, setFilters] = useState([]);
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);

  useEffect(() => {
    if (section.category) {
      setFilters([section.category]);
    } else {
      let urlParams = new URLSearchParams(window.location.search);
      setFilters(urlParams.get("filters")?.toLowerCase().split(",") || []);
    }
  }, []);

  useEffect(() => {
    setNodes(
      !filters
        ? nodes
        : nodes.filter((n) =>
          !filters.length || filters[0] === ""
            ? true
            : matchCategories(n, MENU, filters)
        )
    );
  }, [filters]);

  return (
    <ul className="flex flex-wrap mb-4">
      {MENU.map((m, i) =>
        !(collapse && m.collapse) ? (
          !m.divider ? (
            <li className="mr-2 font-medium last:mr-0" key={i}>
              <Link
                to={`/resources/signals/?filters=${m.slug === "" ? [] : m.slug
                  }`}
                className={cn(
                  "py-2 px-3 rounded-lg inline-block my-2 flex items-center blend-mode-overlay transition-colors duration-200",
                  (m.slug !== "" && filters && filters.includes(m.slug)) ||
                    ((filters.length === 0 ||
                      (filters.length === 1 && filters[0] === "")) &&
                      m.slug === "")
                    ? "text-white bg-dark-90"
                    : site?.doc.backgroundColor
                      ? "bg-light-80 hover:bg-dark-5"
                      : "bg-dark-5 hover:bg-dark-10"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setFilters([m.slug]);
                  window.history.pushState({}, "", `?filters=${m.slug}`);
                }}
              >
                {m.emoji && (
                  <ImageBuilder
                    src={`/static/img/${m.emoji}`}
                    className="h-4 mr-1"
                  />
                )}
                {m.label}
              </Link>
            </li>
          ) : (
            <li className="flex justify-center items-center">
              <div className="ml-3 mr-6 rounded-full bg-dark-30 w-2 h-2"></div>
            </li>
          )
        ) : null
      )}
      {/* <li>
        <select
          name="source"
          className="py-2 px-3 rounded-lg font-semibold inline-block my-2 flex items-center bg-dark-5 hover:bg-dark-10 mr-2"
          onChange={() => {
            // setFilters(e.target.value);
            // window.history.pushState({}, "", `?filters=${e.target.value}`);
          }}
        >
          <option key="">Source</option>
          {CHANNELS.map((m) => (
            <option key={m.value}>{m.title}</option>
          ))}
        </select>
      </li> */}
    </ul>
  );
};
