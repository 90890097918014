import React, { useContext, useState } from "react";
import PortableText from "../portableText";
import { SiteContext } from "../global/site-context";
import { toTextBlock } from "../../lib/helpers";
import { HubspotWebform } from "./hubspot-webform";
import { ImageBuilder } from "../global/image-builder";

const HUBSPOT_FORM_ID = "4f6de55d-b026-415c-a658-9fdde409ddb5";

export const PromoBlock = ({ node, className }) => {
  const site = useContext(SiteContext);

  return (
    <div {...{ className }}>
      <Promo {...{ ...site?.doc?.promo, _rawIntro: toTextBlock(node.intro) }} />
    </div>
  );
};

export const Promo = ({
  _rawOffer,
  buttonText,
  file,
  externalUrl,
  previewImage,
  _rawIntro,
  formIntro,
}) => {
  const [submitted, setSubmitted] = useState();

  return (
    <>
      {_rawIntro && <PortableText blocks={_rawIntro} className="mb-6 italic" />}
      <div className="grid grid-cols-1/3-2/3 gap-5 bg-dark-5 rounded-xl p-5">
        <div>
          <div className="relative flex justify-center items-center rounded-xl border-2 border-black overflow-hidden bg-cover bg-center bg-no-repeat">
            {previewImage && (
              <ImageBuilder image={previewImage} width={260} height={240} />
            )}
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-light-60"></div>
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-dark-10"></div>
            <ImageBuilder
              src="/static/img/icons/lock.svg"
              className="absolute top-50 right-50 z-20 w-12"
            />
          </div>
        </div>
        <div>
          <div className="font-semibold tracking-tight mb-4">
            {_rawOffer && <PortableText blocks={_rawOffer} />}
          </div>
          <div className="relative z-10">
            {submitted ? (
              <p>
                <a
                  href={file ? file.asset.url : externalUrl}
                  className="text-link font-bold"
                  target="_blank"
                >
                  Click to {buttonText}
                </a>
              </p>
            ) : (
              <>
                <HubspotWebform
                  section={{ formId: HUBSPOT_FORM_ID, buttonText }}
                  onFormSubmitted={() => {
                    window.open(file ? file.asset.url : externalUrl);
                    setSubmitted(true);
                  }}
                  intro={formIntro}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
