import React from "react";
import { cn } from "../../lib/helpers";

import * as styles from "./sparkles.module.css";

export const SparklesIcon = ({ animate }) => (
  <div className={cn(styles.container, animate ? styles.animate : null)}>
    <img src="/static/img/icons/sparkle_large.svg" />
    <img src="/static/img/icons/sparkle_small.svg" />
    <img src="/static/img/icons/sparkle_small.svg" />
  </div >
);
