import { mapEdgesToNodes } from "./helpers";

export const PREFER_BLOG = "blog";
export const MAX_SUGGESTED_POSTS = 6; // plus promo

/**
 * Assemble suggetions from query data.
 * Related content includes ALL matching any category, see gatsby-node.js
 *
 * @param {*} data
 * @returns {[*]}
 */
export const suggest = (data, preference) => {
  const doc = data.doc;
  const recentPosts = data.recentPosts ? mapEdgesToNodes(data.recentPosts) : [];
  const posts = sortSimilar(doc, doc.relatedPosts) || [];
  const playbooks = sortSimilar(doc, doc.relatedPlaybooks) || [];
  const stories = sortSimilar(doc, doc.relatedStories) || [];

  return [
    ...new Map(
      preference === PREFER_BLOG
        ? posts
          // change when stories launches
          .slice(0, MAX_SUGGESTED_POSTS - 1)
          .concat(stories.slice(0, 1))
          .concat(recentPosts.slice(0, 1))
          .map((p) => [p.id, p])
        : posts
          .slice(0, MAX_SUGGESTED_POSTS - 1)
          .concat(stories.slice(0, 1))
          .concat(playbooks.slice(0, 1))
          .concat(recentPosts)
          .map((p) => [p.id, p])
    ).values(),
  ];
  // keep related first
  // const suggest = unique.sort(
  //   (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
  // );
};

/**
 * Sort list of docs by most matching categories
 *
 * @param {*} node
 * @param {[*]} nodes
 * @returns {[*]}
 */
const sortSimilar = (node, nodes) => {
  let max = 0;
  // let best;

  if (!nodes) return;

  // @todo convert to reduce
  const scores = nodes.map((current) => {
    const matched = current.categories.filter((c) =>
      node.categories.find((f) => f._id === c._id)
    );

    if (matched.length > max) {
      max = matched.length;
      // best = current;
    }

    return {
      score: matched.length,
      node: current,
    };
  });

  return scores.sort((a, b) => b.score - a.score).map((s) => s.node);
};
