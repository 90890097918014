// extracted by mini-css-extract-plugin
export const panels = "column-content-module--panels--2EIAV";
export const panelsColors = "column-content-module--panelsColors--3_GQ4";
export const cards = "column-content-module--cards--PPJKx";
export const quotes = "column-content-module--quotes--o7nLy";
export const tiles = "column-content-module--tiles--1ZArv";
export const developersIcons = "column-content-module--developersIcons--1-1Z_";
export const panelsGrayDark = "column-content-module--panelsGrayDark--1d9GO";
export const panelsGray = "column-content-module--panelsGray--3Nqss";
export const panelsYellow = "column-content-module--panelsYellow--1my0L";
export const panelsLavender = "column-content-module--panelsLavender--3SH-0";
export const panelsBorder = "column-content-module--panelsBorder--3-SGL";
export const unevenColumns = "column-content-module--unevenColumns--6D2UA";
export const graphicBento = "column-content-module--graphicBento--3IDBs";
export const graphicBento5 = "column-content-module--graphicBento5--33SQl";
export const graphicsRounded = "column-content-module--graphicsRounded--1SWzN";
export const graphicFlipFlop = "column-content-module--graphicFlipFlop--2oMAs";
export const graphicFlipFlopDark = "column-content-module--graphicFlipFlopDark--6Pf4d";
export const hero = "column-content-module--hero--3jO5W";
export const wiggleArrive = "column-content-module--wiggleArrive--2Q5XB";
export const wiggleArriveEven = "column-content-module--wiggleArriveEven--a9gca";
export const wiggleArriveOdd = "column-content-module--wiggleArriveOdd--Kdsmy";