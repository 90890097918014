import React, { useContext, useLayoutEffect, useState } from "react";
import { Card } from "./card";
import { cn } from "../../lib/helpers";
import { CardContext } from "./context";
import { SiteContext } from "../global/site-context";
import { Button } from "../button";
import { ArrowLeft } from "../icon/arrow-left";
import { SectionContext } from "../sections/context";

// referenced by index number, no map
const columnClasses = [
  "",
  "",
  "sm:grid-cols-2",
  "md:grid-cols-2 xl:grid-cols-3",
  "sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
  "md:grid-cols-3 lg:grid-cols-5",
];

export const CardGrid = ({
  title,
  nodes,
  browseMoreHref,
  browseMoreText,
  showSummary,
  allowSummary,
  showByline,
  hideLabels,
  hideDate,
  showSubTags,
  hideTitles,
  minimal,
  maxColumns,
  hideMore,
  cta,
  panels,
  makeThumbnail,
  showExtended,
  attribution,
  hideType,
  bonusCard,
  cardClasses,
  isMasonry,
}) => {
  // avoids useState + useEffect to ensure SSR
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);
  const [isAdmin, setIsAdmin] = useState(site.isAdmin);

  const colCount = maxColumns
    ? maxColumns
    : nodes.length < 4
      ? nodes.length
      : null;

  useLayoutEffect(() => {
    setIsAdmin(site.isAdmin);
  }, []);

  return (
    <>
      {title && <h2 className="mb-6 text-sm uppercase">{title}</h2>}
      <ul
        className={cn(
          "grid",
          minimal
            ? "gap-8"
            : maxColumns === 2 && !panels
              ? "gap-12"
              : panels
                ? "gap-4"
                : "gap-3 gap-y-12",
          columnClasses[colCount]
        )}
        data-swiftype-index="false"
      >
        {nodes &&
          nodes.map((node, index) => {
            const suggestionAdded = // one before the third slot
              (maxColumns === 3 && index === 1) ||
              // one before the fourth slot
              (maxColumns === 2 && index === 0);

            return (
              <React.Fragment key={index}>
                <li className="relative">
                  {node && (
                    <CardContext.Provider
                      value={{
                        ...node,
                        showSummary,
                        showByline,
                        cta: node.cta || cta,
                        maxColumns: colCount,
                        hideLabels,
                        showSubTags,
                        showExtended,
                        minimal,
                        allowSummary,
                        gated: node.formId,
                        makeThumbnail,
                        hideTitles,
                        hideDate,
                        attribution,
                        hideType,

                        // @todo determine in gatsby-node
                        isMasonry,
                        isVideo:
                          node._type === "video" ||
                          node.videoId ||
                          node.mainVideo?.url ||
                          node?.categories?.find(
                            (c) =>
                              c._id === "d5c00b46-ae0d-459b-9071-93fa77309428"
                          ),
                      }}
                    >
                      <Card className={cardClasses} />

                      {isAdmin && <AdminLink doc={node} />}
                    </CardContext.Provider>
                  )}
                </li>

                {bonusCard &&
                  index === bonusCard.position &&
                  bonusCard.component}
              </React.Fragment>
            );
          })}
      </ul>
      {!hideMore && browseMoreHref && (
        <div className="mt-8">
          <Button
            href={browseMoreHref}
            text={browseMoreText || site.token("seeAllPosts")}
            bgColor={section.isDarkBg ? "bg-light-10" : "bg-dark-5"}
            className={section.isDarkBg ? "" : "text-secondary"}
            icon={<ArrowLeft rotate={180} size={12} />}
            iconRight={true}
          // isSmall={true}
          />
        </div>
      )}
    </>
  );
};

const SANITY_URL_PREFIX = "https://www-common-room-studio.netlify.app/desk/";
const TYPE_MAP = {
  post: "blogPosts",
  asset: "assets",
  page: "pages",
  playbook: "playbooks",
  video: "videos",
};
export const AdminLink = ({ doc, noOverlay }) => (
  <a
    href={
      doc._type === "video"
        ? "https://docs.google.com/spreadsheets/d/1yWkgYLsQ4pEqYs_S0eSSzI6YdgiYlr1v_e3g_WlYhyo/edit"
        : `${SANITY_URL_PREFIX}${TYPE_MAP[doc._type]};${doc._id}`
    }
    className={cn(
      "text-link font-bold bg-white p-0.5 px-1.5 text-xs border border-link hover:bg-link hover:text-white rounded-md",
      noOverlay ? null : "absolute top-3 right-3 z-100"
    )}
    target="_blank"
  >
    Edit
  </a>
);
