import { Link } from "gatsby";
import React, { useCallback, useContext } from "react";
import { cn } from "../../lib/helpers";
import { CardContext } from "./context";

// @todo share with configs in filters.js

// Tag structure doc
// https://www.notion.so/commonroomnyc/Blog-categories-13801b2167114a6ca0df484bf9a6038e

const SPOTLIGHT_TAGS = [
  "da38ee48-4ccb-4632-aed4-fae6aeb77b8c", // convos
  "f9701e5b-3434-4c8d-9280-52b2a1dfea87", // rise
  "671866fa-3131-49ce-8be3-e4936cc150c9", // book club
  "5abeceb9-dd3e-4e79-8abc-05a81ac41bd9", // curated
  "361e2200-dc24-4ab1-b465-081150a7464d", // comm-driven
];

const DEVREL_TAG = "acebef1a-78bc-489a-b970-1760f1c33d34";

const BEST_PRACTICES_TAGS = [
  "62c8d09b-665d-4ecb-bc7b-e6769e14963a", // Best practices
  "a2e0ebc4-9fbf-4316-bc12-f4a458c3782d", // guides
  "e2f60e38-805b-4307-81bb-9192be4c7820", // Best Practices - Metrics & ROI
  "5a888a5e-0f6d-487d-a426-877fdc9ce6de", // Best Practices - Insights
  "4a190571-c5a0-4e09-ad3b-ddfe66379c3a", // Best Practices - Engage
  "3ba54205-09a8-4ce2-8d12-866e371555e1", // Best Practices - Grow
  "5793e7d1-0516-48d9-a00d-cfbc05f1084d", // Best Practices - Launch
  "2942ebd3-0be6-44cf-afe8-1ce09a18f2c8", // PLG
  "53a72a1c-4df4-4a20-861e-be6802016af1", // community ops
];

export const COMMUNITY_TAGS = SPOTLIGHT_TAGS.concat([DEVREL_TAG]).concat(
  BEST_PRACTICES_TAGS
);

const KNOWN_TAGS = [
  {
    ids: COMMUNITY_TAGS,
    // color: "blue-100",
    label: "Community",
    path: "/resources/community-teams/",
  },
  {
    ids: ["39d2ca7f-4862-4ab2-b902-0bf10f1d4c34"],
    // color: "orange-800",
    label: "Company news",
    path: "/resources/company-news/",
  },
  {
    ids: ["086bd107-8028-4911-8060-ba5a88f93d5a"],
    // color: "purple-400",
    label: "Product",
    path: "/resources/product/",
  },
  {
    ids: ["6d1042dd-59ad-481b-9257-a209f67eec42"],
    // color: "green-400",
    label: "Customers",
    path: "/customers/",
  },
  // {
  //   ids: SPOTLIGHT_TAGS,
  //   label: "Spotlight",
  //   subTag: true,
  //   path: "/topics/community/spotlight",
  // },
  {
    // used within blog
    ids: [DEVREL_TAG],
    label: "DevRel",
    subTag: true,
    path: "/resources/community/developer-relations/",
  },
  {
    // used within resources
    ids: [DEVREL_TAG],
    label: "DevRel",
    extendedTag: true,
    bulkHeader: "devRel",
  },
  // {
  //   ids: BEST_PRACTICES_TAGS,
  //   label: "Best Practices",
  //   subTag: true,
  //   path: "/topics/community/best-practices",
  // },
  {
    ids: ["df0bb227-e656-4be1-af6a-5b8410d9a26e"],
    label: "Go-to-market",
    extendedTag: true,
    bulkHeader: "gtm",
    subTagParent: true,
  },
  {
    ids: ["067d08c2-833d-447f-8bed-e63211d3c644"],
    label: "Sales",
    bulkHeader: "sales",
    subTag: true,
    path: "/resources/sales/",
  },
  {
    ids: ["0ca9a6b3-3e40-42ed-8e2b-3f798a365fb4"],
    label: "Marketing",
    bulkHeader: "marketing",
    subTag: true,
    path: "/resources/marketing/",
  },
  {
    ids: ["83eadc53-9133-46a5-9be4-dce52fc1e2fb"],
    label: "Community teams",
    extendedTag: true,
    bulkHeader: "community",
    path: "/resources/community-teams/",
  },
];

const TYPE_TAGS = new Map([
  ["post", { label: "Blog", color: "dark-50" }],
  ["asset", { label: "Guide", color: "dark-50" }],
  ["video", { label: "Video", color: "dark-50" }],
  ["playbook", { label: "Playbook", color: "dark-50" }],
  ["story", { label: "Customer story", color: "dark-50" }],
]);

export const Pills = ({ tags, bulkTags = [], showSubTags }) => {
  const card = useContext(CardContext);
  const tagIds = tags ? tags.map((t) => t._id) : [];

  const tagMatches = useCallback(
    // loop through all to find matches
    KNOWN_TAGS.map((tag) => {
      return {
        ...tag,
        matched: tag.ids.filter((t) => tagIds.includes(t)).length > 0,
      };
    })
      .filter((m) => m.matched)
      .concat(bulkTags.map((t) => KNOWN_TAGS.find((k) => k?.bulkHeader === t))),
    [tags, bulkTags]
  );

  // remove guide tag from video pages
  const typePills = []
    .concat(TYPE_TAGS.has(card._type) ? [TYPE_TAGS.get(card._type)] : [])
    // .concat(
    //   card._type === "post" && !card.isVideo
    //     ? [{ label: "Blog", color: "black" }]
    //     : []
    // )
    .concat(
      card._type === "page"
        ? card.isVideo
          ? []
          : card.contentType === "playbook"
            ? [{ label: "Playbook", color: "dark-50" }]
            : [{ label: "Guide", color: "dark-50" }]
        : []
    )
    .concat(
      card._type !== "video" && card.isVideo
        ? [{ label: "Video", color: "dark-50" }]
        : []
    );

  if (!tagMatches && !typePills) return null;

  const renderTags = (card.hideType ? [] : typePills)
    .concat(
      card._type === "story" && !card.hideType
        ? [{ label: "Customers", color: "dark-50" }]
        : []
    )
    .concat(
      card.showExtended
        ? [
          ...new Map(
            tagMatches
              .filter((t) =>
                // card.showSubGroups
                //   ? t?.extendedTag && !t?.subGroupParent
                //   : t?.extendedTag && !t?.subGroup
                card.showSubTags
                  ? t?.extendedTag || t?.subTag
                  : t?.extendedTag
              )
              .map((t) => [t.label, t])
          ).values(),
        ]
        : showSubTags
          ? tagMatches.filter((t) => !t.extendedTag)
          : tagMatches.filter((t) => !t.subTag && !t.extendedTag)
    );

  return renderTags.map((tag) => (
    <Pill {...tag} linked={showSubTags} key={tag.label} />
  ));
};

export const Pill = ({ label, title, path, color, linked }) => {
  const wrapper = cn(
    "inline-block py-0.5 px-2 rounded-xl text-xs tracking-wide border relative overflow-hidden mr-1 mb-1",
    color
      ? `border-${color} text-${color}`
      : "hidden sm:inline border-dark-10 bg-dark-2 text-dark-60"
  );

  const interior = cn(
    "absolute top-0 left-0 w-full h-full rounded-xl opacity-10",
    `bg-${color}`
  );

  return linked && path ? (
    <a href={path} className={cn(wrapper, "hover:text-link hover:border-link")}>
      {color !== "black" && <div className={interior}></div>}
      {label || title}
    </a>
  ) : (
    <div className={wrapper}>
      <div className={interior}></div>
      {label || title}
    </div>
  );
};
