import React, { useContext } from "react";
import PortableText from "../portableText";
import { cn } from "../../lib/helpers";
import { SectionContext } from "./context";
import { TableOfContents } from "./table-of-contents";
import { ImageBuilder } from "../global/image-builder";

import * as styles from "../portableText.module.css";

// also see: web/src/components/sections/tabbed.js

const alignMap = new Map();
alignMap.set("centered", "text-center mx-auto");

export const PageSectionSimple = ({ section }) => {
  const sectionContext = useContext(SectionContext);
  const toc = section.tableOfContents;
  const mainImage = section.image || section.mainImage;

  return (
    <div className={toc ? "grid md:grid-cols-1/3-2/3 relative" : null}>
      {toc && (
        <div className="md:mr-12">
          <div className="sticky top-24 left-0" style={{ maxWidth: 370 }}>
            <TableOfContents blocks={section._rawContent} />
          </div>
        </div>
      )}
      <div
        className={cn(
          sectionContext.isColumn ||
            toc ||
            sectionContext.container === "full" ||
            ["gradientSpotlights", "gradientSpotlightsSpiral"].includes(
              sectionContext.background
            )
            ? null
            : "sm:w-3/4 mx-auto",
          alignMap.has(section.layout) ? alignMap.get(section.layout) : "",
          toc ? "pt-4" : null
        )}
        id={section.anchor}
      >
        {section._rawContent && (
          <PortableText
            blocks={section._rawContent}
            className={
              sectionContext.isDarkBg
                ? styles.darkBlock
                : sectionContext.isColumn ? styles.checkList : null
            }
          />
        )}
      </div>
      {mainImage ? (
        mainImage?.link ? (
          <a href={mainImage.link}>
            <MainImage section={section} mainImage={mainImage} />
          </a>
        ) : (
          <MainImage section={section} mainImage={mainImage} />
        )
      ) : null}
    </div>
  );
};

const MainImage = ({ section, mainImage }) => (
  <ImageBuilder
    className={
      alignMap.has(section.layout) ? alignMap.get(section.layout) : null
    }
    image={mainImage}
    alt={section.header}
    width={1024}
  />
);
