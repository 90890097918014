import React, {
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useSpringCarousel } from "react-spring-carousel";
import { cn } from "../../lib/helpers";
import PortableText from "../portableText";
import { SiteContext } from "../global/site-context";
import { ImageBuilder } from "../global/image-builder";
import { SLIDE_SPEED } from "./tabbed";
import { SectionContext } from "./context";
import { SparklesIcon } from "../icon/sparkles";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { SparklesIconOnly } from "../icon/sparkles-only";

import * as styles from "../global/global.module.css";

/**
 * Auto-advance grabbable card slider with navigation.
 *
 * @todo avoid duplicate code in: carousel-slides.js tabbed.js card-carousel.js carousel-cardswipe.js
 */

const SLIDES_PER_CARD = {
  default: {
    large: 3.5,
    notMobile: 2.2,
    mobile: 1.2,
  },
  big: {
    large: 1.3,
    notMobile: 1.2,
    mobile: 1.1,
  },
  hero: {
    large: 2.2,
    notMobile: 1.6,
    mobile: 1.1,
  },
};

const HERO_DELAY_START = 1000;

export const CarouselCardSwipe = ({
  docs,
  renderItem = (props) => <CarouselCard {...props} />,
  speed,
  header,
  index,
  skipNav,
  skipSummary,
  showSparkles,
  section,
}) => {
  const site = useContext(SiteContext);
  const sectionContext = useContext(SectionContext);
  const [isAutoplayed, setIsAutoplayed] = useState(false);
  const [active, setActive] = useState(0);
  const [started, setStarted] = useState(false);
  const autoPlayRef = useRef();
  const [hasPlayed, setHasPlayed] = useState(-1);
  const [ref, inView] = useInView({
    triggerOnce: true,
    initialInView: index === 0,
    threshold: 0.25,
    triggerOnce: true
  });

  const isVideoSlides = site.isHomepage && sectionContext.layout === "carouselCards";
  const isLooping = !isVideoSlides;
  const isHero = index === 0 || sectionContext.layout === "carouselCardsHero";

  const perCardConfig = isHero
    ? SLIDES_PER_CARD.hero
    : isVideoSlides
      ? SLIDES_PER_CARD.big
      : SLIDES_PER_CARD.default;

  useLayoutEffect(() => {
    if (inView && !started && !isAutoplayed) {
      if (index === 0) {
        // delay hero carousels
        autoPlayRef.current = setInterval(() => {
          setIsAutoplayed(true);
          setStarted(true);
          console.debug("autoplay TRUE -- hero delay", section?.header);
        }, HERO_DELAY_START);
      } else if (!isVideoSlides || true) {
        // videos autoplay only if hasn't played all yet, note dummy slide
        setIsAutoplayed(true);
        setStarted(true);
        console.debug("autoplay TRUE", section?.header);
      }
    }

    if (isAutoplayed) {
      console.debug("AUTOPLAY GOING", section?.header);
      autoPlayRef.current = setInterval(() => {
        if (isLooping || active < docs.length) {
          setActive(active + 1);
          setHasPlayed(active);
          // slideToNextItem();
        }
        slideToNextItem();
      }, speed || SLIDE_SPEED);
    }

    return () => clearInterval(autoPlayRef.current);
  }, [isAutoplayed, inView, active]);

  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    withLoop: isLooping,
    // withLoop: sectionContext.layout === "carouselCardsHero" && sectionContext.index !== 0 ? false : true,
    // slideType: "fluid",
    // slideAmount: 500, //docs?.[0]?.mainImage.asset.metadata.dimensions.width,
    // freeScroll: true,
    // enableFreeScrollDrag: true,
    // initialStartingPosition: "center",
    // disableGestures: isVideoSlides,
    gutter: site.isNotMobile ? 16 : 0,
    itemsPerSlide: site.isLarge
      ? perCardConfig.large
      : site.isNotMobile
        ? perCardConfig.notMobile
        : perCardConfig.mobile,
    items: (isVideoSlides ? docs.concat({ dummy: true }) : docs).map((doc, i) => ({
      id: doc._id,
      renderItem: renderItem({ doc, skipSummary, i, active, started, isVideoSlides, showSparkles, hasPlayed }),
    })),
  });

  useListenToCustomEvent((e) => {
    if (e.eventName === "onDrag") {
      site.metrics.logEvent({
        category: "interactive",
        action: "carousel-drag",
        label: "cardswipe",
      });
      // minus two because of dummy slide
      if (!isVideoSlides || hasPlayed >= docs.length - 2) {
        setIsAutoplayed(false);
        console.debug("autoplay FALSE -- drag");
      }
    } else if (e.eventName === "onSlideChange") {
      if (!isVideoSlides || hasPlayed >= docs.length - 2) {
        setActive(e.currentItem.index);
      }
    }
  });

  return (
    <div className="relative pt-1" ref={ref}>
      {!skipNav && (
        <div className={cn(
          "md:flex items-center mb-4 sm:mb-6 mr-4 lg:mr-auto",
          index === 0 ? "justify-start" : "justify-end",
        )}>
          {(header || section._rawSummary) && index !== 0 && (
            <div className="flex-grow sm:mr-8 text-xl leading-tight">
              {header}
              {section?._rawSummary && <PortableText blocks={section?._rawSummary} />}
            </div>
          )}
          <div className="flex">
            <a
              onClick={() => {
                slideToPrevItem();
                // minus two because of dummy slide
                if (!isVideoSlides || hasPlayed >= docs.length - 2) {
                  console.debug("autoplay FALSE -- click");
                  setIsAutoplayed(false);
                }
              }}
              className={cn(
                "mr-2",
                styles.carouselButton,
                sectionContext.isDarkBg
                  ? styles.carouselButtonDark
                  : null,
              )}
              role="button"
              aria-label="previous"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="sm" />
            </a>
            {/* {isVideoSlides && (
              <a
                onClick={() => {
                  setActive(0);
                  slideToPrevItem();
                  slideToPrevItem();
                }}
                className={cn(
                  "mr-2",
                  styles.carouselButton,
                  sectionContext.isDarkBg
                    ? styles.carouselButtonDark
                    : null
                )}
                role="button"
                aria-label="reset"
              >
                <FontAwesomeIcon icon={faRefresh} size="sm" />
              </a>
            )} */}
            <a
              onClick={() => {
                slideToNextItem();
                // minus two because of dummy slide
                if (!isVideoSlides || hasPlayed >= docs.length - 2) {
                  console.debug("autoplay FALSE -- click");
                  setIsAutoplayed(false);
                }
              }}
              className={cn(
                styles.carouselButton,
                sectionContext.isDarkBg
                  ? styles.carouselButtonDark
                  : null
              )}
              role="button"
              aria-label="next"
            >
              <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </a>
            {/* <div>
              hasPlayed: {hasPlayed}<br />
              docs.length: {docs.length}<br />
              active: {active}<br />
              isAutoplayed: {isAutoplayed ? "true" : "false"}<br />
              started: {started ? "true" : "false"}<br />
              inView: {inView ? "true" : "false"}<br />
              {`hasPlayed >= docs.length - 2`}: {hasPlayed >= docs.length - 2 ? "true" : "false"}<br />
            </div> */}
          </div>
        </div>
      )}
      <div>
        <div className={cn(
          !isVideoSlides ? styles.fullWidthCarousel : styles.fullWidthCarouselVideos,
          // site.isHomepage ? "overflow-hidden" : null
          // sectionContext.layout === "carouselCards" && docs.length ? "mx-auto" : null,
        )}
        //style={site.isNotMobile ? null : { width: "100vw", marginLeft: "-1rem" }}
        >
          {site.isNotMobile ? (
            <div className={styles.fullWidthCarouselSpacer}></div>
          ) : null}
          <div
            className={cn(
              !site.isHomepage ? "overflow-hidden" : null,
              skipSummary ? "pb-4" : null,
            )}
          >
            {carouselFragment}
          </div>
        </div>
      </div>
    </div >
  );
};

const SLIDE_TEXT_COLORS = [
  "white", "lemon", "link"
]

const CarouselCard = ({ doc, skipSummary, i, active, started, isVideoSlides, showSparkles, hasPlayed }) => {
  const site = useContext(SiteContext);
  const hasVideo = doc.mainVideo && doc.mainVideo.asset.extension === "mp4";

  return (
    <div
      className={cn(
        // select-none
        "relative transition-opacity duration-200 mr-4 md:mr-0",
        skipSummary ? "rounded-xl overflow-hidden" : null,
        showSparkles ? "shadow-lg" : null,
        styles.grabbable,
        // isVideoSlides && i === 2 ? "md:pr-48" : null
        // !isVideoSlides || isVideoSlides && (i === active || i === active + 1 || i === active - 1) ? "opacity-100" : "opacity-0",
      )}
      // NOTE...............................................
      style={isVideoSlides && doc.mainImage ? {
        maxHeight: Math.ceil(doc.mainImage.asset.metadata.dimensions.height),
        maxWidth: Math.ceil(doc.mainImage.asset.metadata.dimensions.width),
      } : null}
    // style={isVideoSlides && docs?.[0]?.mainImage ? { maxHeight: Math.ceil(docs[0].mainImage.asset.metadata.dimensions.height / 2) } : null}
    >
      <div className="relative">
        {doc.mainImageFade ? (
          <>
            <ImageBuilder
              image={doc.mainImageFade}
              alt={doc.title}
              draggable="false"
              className={cn(
                "rounded-lg transition-opacity duration-500",
                hasVideo ? null : "absolute top-0 left-0",
                hasVideo ?
                  (i === active && i > hasPlayed) && started ? "opacity-0" : "opacity-100"
                  : i === active && started ? "animate-delayRemove" : null
              )}
              style={isVideoSlides ? { maxHeight: Math.ceil(doc.mainImage.asset.metadata.dimensions.height / 2) } : null}
            />
            {showSparkles && (
              <div className={cn("absolute", i === active && started ? "animate-delayRemove" : null)}
                style={{ top: "7.8%", left: "6.5%" }}
              >
                <div className={cn(
                  "hidden md:block  bg-white rounded-full flex items-center justify-center",
                )}
                  style={{ height: "6%" }}>
                  <SparklesIcon animate={true} />
                </div>
                <SparklesIconOnly
                  alt="sparkles"
                  className="w-3 h-3 md:hidden absolute text-black"
                />
              </div>
            )}
          </>
        ) : null}
        {hasVideo ? (
          <React.Fragment key={doc.mainVideo.asset.url}>
            {started ?
              (i === active && i > hasPlayed) ? (
                <>
                  <ImageBuilder
                    image={doc.mainImage}
                    className="rounded-xl absolute top-0 left-0"
                    draggable="false"
                    style={isVideoSlides ? { maxHeight: Math.ceil(doc.mainImage.asset.metadata.dimensions.height / 2) } : null}
                  />
                  <video
                    // loop={true}
                    muted={true}
                    preload="auto"
                    playsInline={true}
                    autoPlay={true}
                    // width={width}
                    // width={doc.mainImage.asset.metadata.dimensions.width}
                    height={doc.mainImage.asset.metadata.dimensions.height}
                    // poster={imageBuilder(doc.mainImage)
                    //   .width(doc.mainImage.asset.metadata.dimensions.width * 2)
                    //   .quality(99)
                    //   .auto("format")
                    //   .url()}
                    style={isVideoSlides ? { maxHeight: Math.ceil(doc.mainImage.asset.metadata.dimensions.height / 2) } : null}
                    className="rounded-xl absolute top-0 left-0 object-left-top"
                    draggable="false"
                  >
                    <source src={doc.mainVideo.asset.url} type="video/mp4" />
                  </video>
                </>
              ) : i <= hasPlayed ? (
                <ImageBuilder
                  image={doc.mainImage}
                  className="rounded-xl absolute top-0 left-0"
                  draggable="false"
                />
              ) : null
              : null}
          </React.Fragment>
        ) : doc.mainImage ? (
          <ImageBuilder
            image={doc.mainImage}
            alt={doc.title}
            // width={doc.mainImage.asset.metadata.dimensions.width}
            // height={doc.mainImage.asset.metadata.dimensions.height}
            draggable="false"
          />
        ) : null}
      </div>

      {!skipSummary && (
        <div className={cn("pt-4 md:pt-6 max-w-9/10 md:max-w-3/4", `text-${SLIDE_TEXT_COLORS[i]}`)}>
          <h4
            className={cn(
              "font-medium text-lg mb-2",
              doc.title ? "text-link" : null
            )}
          >
            {doc.title || doc.header}
          </h4>
          {doc.headline ? (
            <h5 className="font-semibold mb-3">{doc.headline}</h5>
          ) : doc._rawContent ? (
            <PortableText blocks={doc._rawContent} className="text-sm leading-snug md:text-base" />
          ) : null}
          {doc._rawExcerpt && (
            <PortableText
              blocks={doc._rawExcerpt}
              className="text-sm md:text-base leading-tight text-secondary"
            />
          )}
        </div>
      )}
    </div>
  )
};

const Video = ({ video, className }) =>
  video ? (
    <video
      controls={true}
      preload="auto"
      playsInline={true}
      // width={width}
      // poster={imageBuilder(image)
      //   .width(width * 2)
      //   .quality(99)
      //   .auto("format")
      //   .url()}
      className={cn("rounded-xl min-h-48", className)}
    >
      <source src={video.asset.url} type="video/mp4" />
    </video>
  ) : null;
