import React, { useContext, useLayoutEffect } from "react";
import HubspotForm from "react-hubspot-form";
import { SiteContext } from "../global/site-context";
import { trackableFields } from "../web-form";
import {
  FIRST_SEEN_STASH,
  clientAttribution,
  getSimpleDate,
} from "../../lib/client-attribution";
import { ATTRIBUTION_PARAM } from "./snowflake/navigation";
import { cn, getStashed } from "../../lib/helpers";
import { DELAY_FORM_SUBMIT, HAS_SENT_CONTACT_STASH } from "../sticky-popup";
import { SectionContext } from "./context";

export const CONTACT_FORM_ID_HUBSPOT = "34796c27-056e-431a-a789-1d3eabe627e3";

const hiddenFieldMap = new Map();
hiddenFieldMap.set("campaignType", "campaign_type__most_recent_");
hiddenFieldMap.set("pageTitle", "event_title__most_recent_");
hiddenFieldMap.set("eventDate", "event_date__most_recent_");
hiddenFieldMap.set("pageUrl", "offer_page_url__most_recent_");
hiddenFieldMap.set("eventMeetingUrl", "event_url__most_recent_");
hiddenFieldMap.set("eventAddress", "event_address__most_recent_");

export const HubspotWebform = ({
  section = {},
  doc,
  className,
  minHeight,
  onFormSubmitted,
  attribution,
  intro,
}) => {
  const site = useContext(SiteContext);
  const sectionContext = useContext(SectionContext);

  useLayoutEffect(() => {
    window.addEventListener("message", msgHandler);
    return () => {
      window.removeEventListener("message", msgHandler);
    };
  }, []);

  const msgHandler = (event) => {
    if (event.data.type !== "hsFormCallback") return;

    if (event.data.eventName === "onFormSubmitted") {
      site.metrics.logEvent({
        label:
          doc?._type === "event" ? "sign up" : attribution?.label || "send",
        category: "button_click",
        action:
          doc?._type === "event" ? "event" : attribution?.action || "contact",
      });

      if (onFormSubmitted) {
        onFormSubmitted(event);
      }

      if (!section.formId || section.formId === CONTACT_FORM_ID_HUBSPOT) {
        site.metrics.analytics.storage.setItem(HAS_SENT_CONTACT_STASH, true);
      }
    }

    if (event.data.eventName === "onFormReady") {
      // Required to avoid submit errors
      // https://github.com/escaladesports/react-hubspot-form/issues/22
      const script = document.createElement("script");
      script.src =
        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
      let jqueryScript = document.getElementsByTagName("script");
      let src = Array.from(jqueryScript).filter(
        (item) => item.src === script.src
      );
      if (src.length === 0) {
        document.body.appendChild(script);
      }

      clientAttribution({
        isActive: site.isActive,
        storage: site.metrics.analytics.storage,
        referrer: document.referrer,
        callback: (urlParams, storage) => {
          const setField = (fieldName, value) => {
            const wrapper = document
              .querySelector("iframe[class=hs-form-iframe]")
              .contentDocument.querySelector(`.hs_${fieldName}`);

            const input = document
              .querySelector("iframe[class=hs-form-iframe]")
              .contentDocument.querySelector(
                `input[name=${fieldName}],select[name=${fieldName}]`
              );

            // admin visibility
            const showHidden = urlParams.has("show");

            // show hidden fields
            if (showHidden && wrapper && input && input.type === "hidden") {
              wrapper.style.display = "block";
              input.type = "text";
            }

            // hide pre-fill fields
            if (!showHidden && wrapper && section?.formFieldsPresets?.find((f) => f.label === fieldName)) {
              wrapper.style.display = "none";
            }

            if (input && value) {
              input.value = value;
              input.dispatchEvent(new Event('input', { bubbles: true }));
            }
          };

          section.hiddenFields?.forEach((f) => {
            if (hiddenFieldMap.has(f.name)) {
              setField(hiddenFieldMap.get(f.name), f.value);
            }
          });

          // pre-fill from CMS
          section?.formFieldsPresets?.forEach((f) => {
            setField(f.label, f.value);
          });

          trackableFields.forEach((id) => {
            setField(
              `utm_parameter___${id}`,
              urlParams.get(`utm_${id}`)
            );
            setField(
              `utm_parameter___${id}_original`,
              storage.getItem(`origin.urlParams.${id}`)
            );
          });

          [
            {
              input: "page_cta_path_on_website",
              value: attribution?.website || urlParams.get(ATTRIBUTION_PARAM),
            },
            {
              input: "first_seen_on_website",
              value: getSimpleDate(FIRST_SEEN_STASH, storage),
            },
            {
              input: "utm_parameter___created_date",
              value: getSimpleDate(
                `urlParams.${trackableFields.find((f) =>
                  getStashed({ id: `urlParams.${f}`, storage })
                )}.created`,
                storage
              ),
            },
            {
              input: "utm_parameter___created_date_original",
              value: getSimpleDate(
                `origin.urlParams.${trackableFields.find((f) =>
                  storage.getItem(`origin.urlParams.${f}`)
                )}.created`,
                storage
              ),
            },
          ].forEach((field) => {
            setField(field.input, field.value);
          });
        },
      });
    }
  };

  return (
    <div style={{ minHeight }} className={cn("relative", className)}>
      <div
        className={cn(
          "relative absolute z-50 rounded-xl p-6 border",
          sectionContext.isDarkBg
            ? "bg-light-20 border-dark-10"
            : "bg-white border-dark-5"
        )}
      >
        {intro}
        <HubspotForm
          portalId="21450559"
          formId={section.formId || CONTACT_FORM_ID_HUBSPOT}
          region="na1"
          loading={<div>Loading...</div>}
          onSubmit={() => {
            if (section.redirectUrl) {
              setTimeout(() => {
                window.location.href = section.redirectUrl;
              }, DELAY_FORM_SUBMIT);
            }
          }}
          // onReady={(form) => {}}
          translations={
            section.buttonText
              ? {
                en: {
                  submitText: section.buttonText,
                },
              }
              : {}
          }
        />
      </div>
    </div>
  );
};
