import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cn } from "../../../lib/helpers";
import { InfoIcon } from "./pricing-plans";
import { ShowEmoji } from "../../emoji";
import ReactTooltip from "react-tooltip";
import { CheckMark } from "../../icon/check-mark";
import { SiteContext } from "../../global/site-context";

import * as styles from "./pricing-plans.module.css";

export const PricingComparison = ({ section }) => {
  const { pricing } =
    useStaticQuery(
      graphql`
        query PricingComparisonQuery {
          pricing: sanityPricingWrapper(_id: { eq: "PricingTiers" }) {
            panels {
              _key
              title
              price
              priceDetails
              _rawSummary(resolveReferences: { maxDepth: 5 })
              action
              actionDetails
              details {
                _key
                title
                _rawSummary(resolveReferences: { maxDepth: 5 })
              }
              integrations
            }
            comparison {
              _key
              title
              summary
              summaryTooltip
              free
              freeMessage
              freeTooltip
              freePath
              team
              teamMessage
              teamTooltip
              teamPath
              start
              startMessage
              startTooltip
              startPath
              enterprise
              enterpriseMessage
              enterpriseTooltip
              enterprisePath
              emoji
            }
          }
        }
      `
    ) || {};

  return (
    <div className="text-lg">
      {pricing?.comparison.map((r, i) => (
        <FeatureCompareRow
          panel={r}
          key={r._key}
          next={pricing?.comparison[i + 1]}
        />
      ))}
    </div>
  );
};

const FeatureCompareRow = ({ panel, next }) => {
  const site = useContext(SiteContext);
  const isHeaderRow = !panel.free && !panel.team && !panel.enterprise;

  return (
    <div
      className={cn(
        site.isActive("freePlan") ? "grid grid-cols-6" : "grid grid-cols-5",
        isHeaderRow ? "mt-8 sm:rounded-t-xl overflow-hidden" : "bg-white odd:bg-light-40",
        !next?.free && !next?.team && !next?.enterprise ? "sm:rounded-b-xl" : null
      )}
    >
      <FeatureCompareCell
        {...{ isHeaderRow }}
        isTitleCell={true}
        message={
          <span className="flex items-center">
            <ShowEmoji name={panel.emoji} className="w-8 h-8 mr-2" />{" "}
            {panel.title}
          </span>
        }
        summary={panel.summary}
        tooltip={panel.summaryTooltip}
        _key={`${panel._key}-summary`}
      />
      {site.isActive("freePlan") && (
        <FeatureCompareCell
          {...{ isHeaderRow }}
          message={isHeaderRow ? "Free" : panel.freeMessage}
          tooltip={panel.freeTooltip || null}
          isChecked={panel.free}
          _key={`${panel._key}-free`}
          path={panel.freePath}
        />
      )}
      <FeatureCompareCell
        {...{ isHeaderRow }}
        message={isHeaderRow ? "Starter" : panel.startMessage}
        tooltip={panel.startTooltip || null}
        isChecked={panel.start}
        checkColor="dark-30"
        _key={`${panel._key}-starter`}
        path={panel.startPath}
      />
      <FeatureCompareCell
        {...{ isHeaderRow }}
        message={isHeaderRow ? "Team" : panel.teamMessage}
        tooltip={panel.teamTooltip || null}
        isChecked={panel.team}
        checkColor="primary"
        _key={`${panel._key}-team`}
        path={panel.teamPath}
      />
      <FeatureCompareCell
        {...{ isHeaderRow }}
        message={isHeaderRow ? "Enterprise" : panel.enterpriseMessage}
        tooltip={panel.enterpriseTooltip || null}
        isChecked={panel.enterprise}
        checkColor="link"
        _key={`${panel._key}-enterprise`}
        path={panel.enterprisePath}
      />
    </div>
  );
};

export const FeatureCompareCell = ({
  isHeaderRow,
  isTitleCell,
  isChecked,
  message,
  checkColor,
  summary,
  tooltip,
  _key,
  path,
}) => (
  <div
    className={cn(
      "p-2 md:p-4 border-l first:border-l-0 items-center flex",
      isHeaderRow ? "bg-primary text-white font-semibold border-light-20" : "border-dark-20",
      isTitleCell
        ? "font-semibold col-span-2"
        : "justify-center text-center md:justify-start md:text-left",
      isTitleCell && isHeaderRow ? "sm:font-bold" : null,
      !isTitleCell && !isHeaderRow ? "justify-center md:justify-start" : null
    )}
  >
    {message ? (
      <div className="text-xs sm:text-sm md:text-base">
        {path ? (
          <a href={path} className="text-link hover:underline">
            {message}
          </a>
        ) : (
          message
        )}
        {summary && <span className="text-sm font-normal">{summary}</span>}
      </div>
    ) : isChecked ? (
      <CheckMark color={checkColor} />
    ) : null}
    {tooltip && (
      <>
        <div
          className="ml-2 text-secondary top-3"
          data-tip={tooltip}
          data-for={_key}
        >
          <InfoIcon />
        </div>
        <ReactTooltip
          effect="solid"
          color="white"
          backgroundColor="#333333"
          className={styles.toolTip}
          offset={{ top: -5 }}
          id={_key}
        />
      </>
    )}
  </div>
);
