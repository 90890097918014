import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { imageBuilder, toPlainText } from "../../lib/helpers";
import { pageDocPath } from "../../../helpers";
import clientConfig from "../../../client-config";

const SHARE_IMAGE_WIDTH = 1536;
const SITE_TITLE = "Common Room";

const PageHeadMeta = ({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  bodyAttributes,
  isTitleOverriden,
  path,
  doc,
}) => {
  const { site } =
    useStaticQuery(graphql`
      query DefaultSEOQuery {
        site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
          description
          twitter
          mainImage {
            ...SanityImageSimple
          }
        }
      }
    `) || {};

  const trueTitle = isTitleOverriden
    ? title || doc.title
    : `${title || doc.title} | ${SITE_TITLE}`;
  const metaDescription =
    description || toPlainText(doc?._rawExcerpt) || site.description || "";
  const pageUrl = process.env.HOST + (path || (doc ? pageDocPath(doc) : ""));
  const isHidden =
    doc?.status !== "published" ||
    doc?.contentType === "landing" ||
    doc?._type === "company";

  const metaImage = imageBuilder(
    image?.asset
      ? image
      : doc?.mainImage?.asset
        ? doc.mainImage
        : site.mainImage
  )
    .width(SHARE_IMAGE_WIDTH)
    .url();

  const twitter = `@${site.twitter}`;
  const twitterTags = {
    card: "summary_large_image",
    site: twitter,
    creator: twitter,
    title,
    description: metaDescription,
    image: metaImage,
    "image:width": SHARE_IMAGE_WIDTH,
    "image:alt":
      "Common Room brand image - mint plant on teal background with motto: People matter most",
  };
  const twitterTagsList = [];
  Object.entries(twitterTags).forEach(([k, v]) =>
    twitterTagsList.push({ property: "twitter:" + k, content: v })
  );

  const includeSanityClient =
    ["help", "resources", "resources-new"].includes(doc?.slug?.current) ||
    ["documentation", "category"].includes(doc?._type);

  return (
    <Helmet
      bodyAttributes={bodyAttributes}
      htmlAttributes={{ lang }}
      title={trueTitle}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: trueTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: SITE_TITLE,
        },
        {
          property: "og:url",
          content: pageUrl,
        },
        {
          property: "og:see_also",
          content: `https://twitter.com/${twitter}`,
        },
        {
          property: "og:image",
          content: metaImage,
        },
      ]
        .concat(isHidden ? { name: "robots", content: "noindex,nofollow" } : [])
        .concat(
          keywords && keywords.length > 0
            ? {
              name: "keywords",
              content: keywords.join(", "),
            }
            : []
        )
        .concat(meta)
        .concat(twitterTagsList)}
      script={
        includeSanityClient ? [{ src: "https://unpkg.com/@sanity/client" }] : []
      }
      link={[
        {
          rel: "icon",
          type: "image/png",
          // sizes: "32x32",
          href: "/static/meta/favicon.svg",
        },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "16x16",
        //   href: "/static/meta/favicon-16x16.png",
        // },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/static/meta/apple-touch-icon.png",
        },
        {
          rel: "preload",
          as: "script",
          href: `https://www.googletagmanager.com/gtag/js?id=${clientConfig.gtm.trackingId}`,
        },
        {
          rel: "dns-prefetch",
          href: "https://www.googletagmanager.com/",
        },
      ]
        .concat(
          doc && doc._type && !isHidden
            ? {
              rel: "canonical",
              href: pageUrl,
            }
            : []
        )
        .concat(
          [
            "SuisseIntl-Light-WebS.woff2",
            "SuisseIntl-Regular-WebS.woff2",
            "SuisseIntl-Bold-WebS.woff2",
            "SuisseIntl-Black-WebS.woff2",
          ].map((f) => {
            return {
              rel: "preload",
              href: `/static/fonts/${f}`,
              as: "font",
              type: "font/woff2",
            };
          })
        )}
    />
  );
};

PageHeadMeta.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
};

PageHeadMeta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default PageHeadMeta;
