import React, { useContext } from "react";
import { trackPath } from "../../../../helpers";
import { Button } from "../../button";
import { SiteContext } from "../../global/site-context";
import PortableText from "../../portableText";
import {
  ATTRIBUTION_PARAM,
  DEMO_PHRASE,
  DEMO_URL,
  SIGNUP_PHRASE,
  SIGNUP_URL,
} from "./navigation";
import { PillHeader } from "../wrapper";

export const DEMO_PAGES = [
  "/demo/",
  "/demo-new/",
  "/contact/",
  "/hello/databricks-bm9kZWpz/",
  "/hello/deel-ZGF0YWJ/",
  "/hello/grafana-5eh8xp3/",
  "/hello/stripe-4twr3iz9o/",
];

export const GetStarted = ({ section }) => {
  const site = useContext(SiteContext);
  const path = trackPath(site?.doc);

  return (
    <>
      <div className="mb-10">
        <PillHeader
          {...section}
          isDarkBg={true}
          background="mint"
          header={site.token("getStartedPill")}
          className="mb-6 lg:mb-10"
        />
        {section?._rawSummary ? (
          <PortableText blocks={section._rawSummary} className="text-xl" />
        ) : (
          <h4 className="text-2xl md:text-4xl font-semibold tracking-tight">
            {site.token("getStartedSummary")}
          </h4>
        )}
      </div>
      <div>
        <Button
          text={DEMO_PHRASE}
          href={`${DEMO_URL}?${ATTRIBUTION_PARAM}=get-started-banner--${path}`}
          bgColor="bg-black"
          isBig={true}
        />
        {/* <Button
          text={SIGNUP_PHRASE}
          href={`${SIGNUP_URL}?${ATTRIBUTION_PARAM}=get-started-banner--${path}`}
          bgColor="bg-white"
          className="mt-1 mb-5"
          isBig={true}
        /> */}
      </div>
    </>
  );
};
