import React, { useContext, useLayoutEffect, useState } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { SectionContext } from "./context";
import { SiteContext } from "../global/site-context";
import { ImageBuilder } from "../global/image-builder";

import * as styles from "../global/global.module.css";

// @todo make limit a setting
export const CustomerLogos = ({ section, nodes }) => (
  <CustomerLogosWall
    section={{ ...section, limit: section?.category === "uncommon" ? 12 : 6 }}
    nodes={nodes}
  />
);

export const CustomerLogosExtended = ({ section }) => (
  <CustomerLogosWall section={section} />
);

const DEFAULTS = "defaults";
const RESPONSIVE_MOBILEMAX = new Map([
  ["homepage", { mobile: 14, tablet: 20 }],
  [DEFAULTS, { mobile: 30, tablet: 32 }],
]);

export const CustomerLogosWall = ({ section, nodes }) => {
  const sectionContext = useContext(SectionContext);
  const site = useContext(SiteContext);
  const [isHidden, setIsHidden] = useState(true);

  const category = section?.category || DEFAULTS;
  const results = useStaticQuery(logosGraphQL) || {};
  const desired = results[category];
  const docList = nodes?.length > 0 ? nodes : desired
    ? mapEdgesToNodes(desired)
    : mapEdgesToNodes(results[DEFAULTS]);

  // avoid logos when "page" is tagged with that company
  // include or exclude elsewhere (prospects)
  const alteredList = site?.doc.companies
    ? site?.doc._type === "page"
      ? docList.filter(
        (logo) => !site?.doc.companies.find((c) => c._id === logo._id)
      )
      : docList
        .concat(site?.doc.companies)
        .filter(
          (c) => !site?.doc?.companiesExclude.find((e) => e._id === c._id)
        )
    : docList;

  const uniqueList = [...new Map(alteredList.map((p) => [p._id, p])).values()];

  const logos =
    section.cards || section.limit
      ? uniqueList.slice(0, section.cards || section.limit)
      : uniqueList;

  // @todo standardize for number per row and responsive trimming
  const responsiveMaxConfig = RESPONSIVE_MOBILEMAX.has(category)
    ? RESPONSIVE_MOBILEMAX.get(category)
    : null;

  const responsiveLogos =
    // demo page
    site.doc.backgroundColor === "purple" && section.background !== "white"
      ? mapEdgesToNodes(results.mono)
      : !responsiveMaxConfig
        ? // no special config
        logos
        : // desktop
        site.isLarge
          ? logos
          : // tablet (md)
          site.isNotMobile
            ? logos.slice(0, responsiveMaxConfig.tablet)
            : // mobile
            logos.slice(0, responsiveMaxConfig.mobile);


  const isNotXL = ["large", "narrow", "thin"].includes(sectionContext?.container);
  const containerThin = ["narrow", "thin"].includes(section?.container);
  const darkBg = sectionContext?.isDarkBg;

  useLayoutEffect(() => {
    setIsHidden(false);
  }, []);

  return (
    <div
      className={cn(
        "grid gap-2 lg:gap-4 grid-cols-2",
        // wait to load until device known for list length
        isHidden ? "hidden" : null,
        styles.fadeIn,
        // within component column layout
        sectionContext.isColumn
          ? null
          : section?.limit
            ? containerThin || responsiveLogos.length === 9
              ? "md:grid-cols-3"
              : "lg:grid-cols-6"
            : // "defaults"
            // landing page mode
            section.category === null || responsiveLogos.length === 12
              ? "md:grid-cols-4 md:mx-auto"
              : section.category !== null && responsiveLogos.length === 6
                ? "grid-cols-3 sm:max-w-2/3 lg:max-w-none mx-auto lg:grid-cols-6"
                : "md:grid-cols-4 xl:grid-cols-6"
      )}
    >
      {responsiveLogos
        .sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        )
        .map((doc, i) => (
          <LogoBox
            key={i}
            alt={doc.title}
            image={darkBg ? doc.mainImageMono : doc.mainImage}
            className={darkBg ? "bg-light-5" : "bg-white"}
            inPanel={true}
            isNotXL={isNotXL}
            isColumn={sectionContext.isColumn}
          />
        ))}
    </div>
  );
};

/**
 * @param {{ alt: any; image: any; isColumn?: any; className: any; onClick?: any; maxHeight?: any; left?: any; }} param0
 */
export const LogoBox = ({
  alt,
  isColumn,
  image,
  className,
  onClick,
  maxHeight,
  left,
  inPanel,
  isNotXL,
}) => (
  <div
    className={cn(
      "flex items-center",
      !left ? "justify-center px-4" : null,
      inPanel ? "min-h-32 rounded-lg border border-dark-5" : null,
      !left ? isNotXL ? "px-3 lg:px-6" : "px-8 sm:px-20 md:px-12 lg:px-8" : null,
      className
    )}
    onClick={onClick}
  >
    {image && (
      <ImageBuilder
        image={image}
        quality={100}
        height={maxHeight || 80}
        style={{
          // maxHeight: maxHeight || isColumn ? "55%" : "80px",
          maxWidth: "80%",
        }}
        alt={`${alt} logo`}

      />
    )}
  </div>
);

export const logosGraphQL = graphql`
  query logosQuery {
    defaults: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        prospect: { ne: true }
        title: { ne: "Common Room" }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    mono: allSanityCompany(
      limit: 6
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImageMono: { asset: { _id: { ne: null } } }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    homepage: allSanityCompany(
      limit: 24
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["429d6b97-3b6d-4f55-9eb8-13589d5ea01a"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    gtm: allSanityCompany(
      limit: 6
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["df0bb227-e656-4be1-af6a-5b8410d9a26e"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    community: allSanityCompany(
      limit: 6
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["83eadc53-9133-46a5-9be4-dce52fc1e2fb"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    devrel: allSanityCompany(
      limit: 6
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["acebef1a-78bc-489a-b970-1760f1c33d34"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    uncommon: allSanityCompany(
      limit: 9
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["5a3d3357-3b24-4764-bf83-01a4fb40f5c4"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    gtmUsers: allSanityCompany(
      limit: 6
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["6342771e-fb66-4e36-a5f6-23571a9f3941"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    coss: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["65e914c7-6b39-487c-88b7-ae1fddc13b29"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    plg: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["2942ebd3-0be6-44cf-afe8-1ce09a18f2c8"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    abx: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["7662f4d2-9875-4dc5-be5e-91dd093375ae"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    revops: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["b0283bcf-1949-4988-ba0e-b43175d42846"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    sales: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["067d08c2-833d-447f-8bed-e63211d3c644"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }

    marketing: allSanityCompany(
      limit: 32
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        mainImage: { asset: { _id: { ne: null } } }
        categories: {
          elemMatch: { _id: { in: ["0ca9a6b3-3e40-42ed-8e2b-3f798a365fb4"] } }
        }
        prospect: { ne: true }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }
  }
`;
