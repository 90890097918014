import React, { useContext } from "react";
import { cn, imageBuilder } from "../../lib/helpers";
import { CardContext } from "./context";
import { SectionContext } from "../sections/context";
import { isLinkedIn, isSlack } from "../sections/quote-panels";
import { LogoBox } from "../sections/logos";
import { ImageBuilder } from "../global/image-builder";

const AVATAR_SIZE = 48;

export const Byline = ({ maxAuthors, hideBorder, showLogo, skipCompany }) => {
  const card = useContext(CardContext);
  const section = useContext(SectionContext);
  const authors = maxAuthors ? card.authors.slice(0, maxAuthors) : card.authors;

  return authors ? (
    <ul className="flex flex-col sm:flex-row flex-wrap gap-4">
      {authors.map((item, i) => (
        <li
          className={cn(
            hideBorder ? "" : "border border-dark-10 rounded-xl p-2"
          )}
          key={`${card?.slug}-${item.author?.name}-${i}`}
        >
          {!card.skipAuthorLink && !card.url && item.author?.url ? (
            <a
              href={item.author.url}
              target="_blank"
              className={
                section.isDarkBg
                  ? "text-white hover:text-yellow-200"
                  : "text-black hover:text-link"
              }
            >
              <Author author={item.author} {...{ showLogo, skipCompany }} />
            </a>
          ) : (
            <Author author={item.author} {...{ showLogo, skipCompany }} />
          )}
        </li>
      ))}
    </ul>
  ) : null;
};

export const Author = ({ author, showLogo, skipCompany }) => {
  const card = useContext(CardContext);
  const section = useContext(SectionContext);

  // imageSrc used for override
  const hasImage = author.imageSrc || author?.image?.asset;
  const isSocial = isLinkedIn(card.url) || isSlack(card.url);

  return author ? (
    <div
      className={cn(
        "flex justify-start",
        showLogo && author.companies ? "items-start" : "items-center"
      )}
    >
      {hasImage && (
        <ImageBuilder
          src={
            author.imageSrc ||
            imageBuilder(author.image)
              .width(AVATAR_SIZE * 2)
              .height(AVATAR_SIZE * 2)
              .quality(100)
              .auto("format")
              .fit("crop")
              .url()
          }
          alt={author.name}
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
          quality={100}
          className="shrink-0 rounded-full"
        />
      )}
      <div className="text-base leading-tight mx-2">
        <p className="font-medium">{author?.name || <em>Anonymous</em>}</p>
        <p
          className={cn(!section.isDarkBg ? "text-secondary" : "text-light-60")}
        >
          <span className="text-sm">
            {author?.title || "Contributor"}
            {!skipCompany && author.companies && author.companies[0] && (
              <>, {author.companies[0].title}</>
            )}
          </span>
        </p>
        {showLogo && author.companies && (
          <LogoBox
            image={author.companies[0].mainImage}
            maxHeight={52}
            left={true}
            alt={author.companies[0].title}
          />
        )}
      </div>
    </div>
  ) : null;
};
