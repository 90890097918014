import React, { useContext } from "react";
import { toPlainText } from "../../../lib/helpers";
import { PodcastLinks } from "./podcast";
import PortableText from "../../portableText";
import { SiteContext } from "../../global/site-context";
import { MISSING_UI_STRING } from "../../global/layout";

export const ResourcesHeader = ({ title, tag, suffix, section, type }) => {
  const site = useContext(SiteContext);
  const isPodcast = ["podcast", "podcast/all"].includes(tag?.slug.current);

  return (
    <div className="mt-20 text-center">
      <div className="max-w-screen-sm mx-auto">
        {(title || suffix || section.header) && (
          <>
            <h1 className="text-3xl lg:text-4xl mb-2 font-bold tracking-tight">
              {title || section.header}
              {suffix}
            </h1>
            {site.token(`resourcesType-${type}`) !== MISSING_UI_STRING && (
              <h2 className="text-lg text-secondary mb-4">
                {site.token(`resourcesType-${type}`)}
              </h2>
            )}
          </>
        )}
        {tag && (
          <div className="max-w-screen-sm mx-auto">
            {tag.headline && (
              <h2 className="text-xl mb-4 text-secondary">{tag.headline}</h2>
            )}
            <h3 className="text-lg font-medium tracking-tight">
              {toPlainText(tag._rawExcerpt)}
            </h3>
          </div>
        )}
        {section?._rawSummary && (
          <PortableText blocks={section._rawSummary} />
        )}
      </div>
      {isPodcast && <PodcastLinks className="mt-2" path={tag?.slug.current} />}
    </div>
  );
};
