import React, { useContext } from "react";
import { pageDocPath, trackPath } from "../../helpers";
import { Button } from "./button";
import PortableText from "./portableText";
import { ATTRIBUTION_PARAM, DEMO_PHRASE, DEMO_URL } from "./sections/snowflake/navigation";
import { SiteContext } from "./global/site-context";
import { cn } from "../lib/helpers";
import { CardContext } from "./card/context";
import { QuoteCardContent } from "./sections/quote-panels";
import { ImageBuilder } from "./global/image-builder";

import * as style from "./global/global.module.css";
import tailwindConfig from "../../tailwind.config";
import { LogoBox } from "./sections/logos";
import { Logo } from "./icon/logo";

// deep link workbooks folks with an account
// https://app.commonroom.io/community/251-common-room/workflows/${node.slug.current}

export const InlineCardWorkflow = ({ node, cta }) => {
  const site = useContext(SiteContext);
  return (
    <div>
      <a
        href={`${DEMO_URL}?${ATTRIBUTION_PARAM}=workflow--${trackPath(node)}`}
        className="group"
        onClick={() =>
          site.metrics.logClick("call-to-action-click", "workflow")
        }
      >
        <div className="border border-dark-10 p-4 my-12 rounded-md bg-dark-2 grid sm:grid-cols-2/3-1/3 shadow-md">
          <div className="flex">
            <div className="mr-3 flex-none">
              <ImageBuilder
                image={node?.integrations[0].mainImage}
                width={48}
                className="mt-1 mx-2.5"
              />
            </div>
            <div>
              <p
                className="text-secondary text-sm font-medium tracking-loose"
                style={{ marginBottom: 0 }}
              >
                Workflow
              </p>
              <h4 className="font-medium text-lg mb-1 group-hover:text-link">
                {node.title}
              </h4>
              <div className="text-sm">
                <PortableText blocks={node.excerpt} />
              </div>
            </div>
          </div>
          <div className="justify-self-end mt-4 sm:mt-0">
            <Button
              text={DEMO_PHRASE}
              bgColor="bg-black"
              noLink={true}
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export const InlineCardPlaybook = ({ node }) => {
  const site = useContext(SiteContext);
  return (
    <div>
      <a
        href={pageDocPath(node)}
        className="bg-white text-black group"
        onClick={() =>
          site.metrics.logClick("call-to-action-click", "playbook")
        }
      >
        <div className="border border-dark-10 p-4 my-12 rounded-md bg-dark-2 grid sm:grid-cols-2/3-1/3 shadow-md">
          <div className="flex">
            <div className="mr-4 flex-none">
              <ImageBuilder
                src="/static/img/emoji-playbook.svg"
                width={64}
                className="mt-1"
              />
            </div>
            <div>
              <p
                className="text-secondary text-sm font-medium tracking-loose"
                style={{ marginBottom: 0 }}
              >
                Playbook
              </p>
              <h4 className="font-medium text-lg mb-1 group-hover:text-link">
                {node.title}
              </h4>
              <div className="text-sm">
                <PortableText blocks={node.excerpt} />
              </div>
            </div>
          </div>
          <div className="justify-self-end mt-4 sm:mt-0">
            <Button
              text="Explore playbook"
              bgColor="bg-black"
              noLink={true}
              href="/playbooks/"
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export const InlineCardQuote = ({ node, skipLogo, simpleLayout }) => (
  <div
    className={cn(
      "rounded-xl overflow-hidden",
      !simpleLayout
        ? "border border-dark-5 my-12 p-4 md:p-12 text-xl leading-normal"
        : null,
    )}
    style={!simpleLayout ? {
      backgroundImage: `url('/static/img/emerald-bg.png')`,
      backgroundBlendMode: "hue",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundColor: tailwindConfig.theme.colors.green.darkPageBg,
    } : null}
  >
    <div
      className={cn(
        "bg-white rounded-xl p-6 md:pl-8 md:pr-10 md:pb-8",
        !simpleLayout
          ? "shadow-xl border-dark-30"
          : "border-dark-20 text-medium"
      )}
    >
      {!simpleLayout && (
        <div className="flex items-center justify-between mb-4">
          <div>
            <LogoBox
              image={node?.companies?.[0]?.mainImage}
              left={true}
            />
          </div>
          <div>
            <Logo iconOnly={true} className="h-10" />
          </div>
        </div>
      )}
      <CardContext.Provider
        value={{
          authors: [
            {
              author: node?.authors ? node.authors[0].author : node?.author,
            },
          ],
        }}
      >
        <QuoteCardContent
          q={{ ...node }}
          boldText={true}
        />
      </CardContext.Provider>
      {/* <blockquote>{node.quote.children}</blockquote> */}

    </div>
  </div>
);
